import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/questionnaire/response/export/csv": {
        required: ["questionnaireId"],
        isDataResponse: true,
    },
    "/questionnaire/response/export/csvtar": {
        required: ["questionnaireId"],
        isDataResponse: true,
    },
    "/questionnaire/response/export/xlsx": {
        required: ["publicationId", "questionnaireId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    csv: (options, args) => apiRequest(options, "/questionnaire/response/export/csv", args),
    csvtar: (options, args) => apiRequest(options, "/questionnaire/response/export/csvtar", args),
    xlsx: (options, args) => apiRequest(options, "/questionnaire/response/export/xlsx", args),
};
