import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/questionnaire/responses/destroy": {
        required: ["questionnaireId"],
        // Do NOT use "booleanResponse" here, we need the token from the first delete 
        // request to make the second request go through.
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    destroy: (options, args) => apiRequest(options, "/questionnaire/responses/destroy", args),
};