 <template>
    <div id="app">
        <div v-for="item in bannerItems"
            :key="item.id"
            class="mpt-survey-notification">
            <div></div>
            <div class="mpt-message">
                <span v-html="$t(item.message, { link: item.link })"></span>
                <o-button 
                    class="mpt-do-not-button"
                    size="small"
                    @click="doNotShowLinkAgain(item.storeLocation, item.id)">
                    {{$t(`customerSurvey.doNotShowAgain`)}}
                </o-button>
            </div>
            <o-button 
                class="mpt-close-button is-ghost"
                @click="closeLink(item.state)">
                <fa-icon :icon="['far', 'times']"></fa-icon>
            </o-button>
        </div>
        <!-- 
            Router view will contain one of the following
            Auth.vue 
            Public.vue
            Browser.vue
            PostEditor.vue
            ProjectEditor.vue
            QuestionnaireEditor.vue
        -->
        <router-view :key="key"></router-view>
    </div>
</template>

<script>
import api from "@/api";
import log from "@/log";
import i18n from "@/i18n";
import store from "@/store";
import { router, lazyImport } from "@/router";
import config from "@/config";

const BrowserWarningPopup = lazyImport("base-elements/BrowserWarningPopup");


/* eslint-disable max-len */
export default {
    name: "app",
    mounted: function () {
        // Chunk cache busting + lazy loading means that right after a deploy, users who have not
        // reloaded since the deploy, will get errors since the chunks they are trying to load
        // have disappeared. 
        //
        // See:
        // https://stackoverflow.com/questions/59385650/vuejs-browser-caching-and-loading-chunk-failed-issue
        // https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
        //
        // This has the potential for an infinite loop :-(. TODO: figure out how to SENTRY if this
        // is looping 
        //
        const helsinkiWfsGeoJsonErr = `Uncaught SyntaxError: Unexpected token '<', "<?xml vers"... is not valid JSON`; // https://github.com/Mapita/maptionnaire2/issues/1282
        const cacheBustingError = "unexpected token '<'";
        window.onerror = function(mMsg){
            const message = "The app has been updated, please reload the page to get the latest changes.";
            if (mMsg.indexOf(helsinkiWfsGeoJsonErr) > -1){
                console.log(`Caught error "${mMsg}". This is ignored because City of Helsinki WFS server causes it when you don't specify layer id.`);
            }
            else if (mMsg.toLowerCase().indexOf(cacheBustingError) > -1){
                if (navigator.onLine){
                    /* eslint no-alert: "off" */
                    if (window.confirm(message)) {
                        window.location.reload();
                    }
                }
            }
        };
        this.showBrowserWarning();
    },
    metaInfo: function () {
        const $t = this.$t.bind(this);
        // For title and title template we need to separately define the strign to be
        // interpolated
        let app = $t(`meta.app`);
        if (app === "meta.app") { app = config.meta.applicationName; }
        const appShort = $t(`meta.appShort`);
        // The AWS CloudFront url path for meta icons etc.
        // Note that manifest.json can not be served from there, must be same origin
        // Same for favicon 
        const assetPath = `${config.awsCloudFrontUrl}/meta`;
        return {
            title: () => app,
            titleTemplate: chunk => `${chunk()}`,
            htmlAttrs: {
                lang: store.state.locale || "en",
            },
            meta: [{
                name: "apple-mobile-web-app-capable",
                content: config.meta.appleMobileWebAppCapable,
            }, {
                name: "apple-mobile-web-app-status-bar-style",
                content: config.meta.appleMobileWebAppStatusBarStyle,
            }, {
                name: "apple-mobile-web-app-title",
                content: $t(`meta.app`),
            }, {
                name: "application-name",
                content: $t(`meta.app`),
            }, {
                name: "mobile-web-app-capable",
                content: config.meta.mobileWebAppCapable,
            }, {
                name: "msapplication-tap-highlight",
                content: config.meta.msapplicationTapHighlight,
            }, {
                name: "msapplication-TileColor",
                content: config.meta.msApplicationTileColor,
            }, {
                name: "msapplication-TileImage",
                content: `${assetPath}/icons/mstile-150x150.png`,
            }, {
                name: "theme-color",
                content: config.meta.themeColor,
            }, {
                vmid: "description",
                name: "description",
                property: "description",
                content: $t(`meta.description`),
            },
            // OpenGraph tags
            {
                name: "og:type",
                property: "og:type",
                content: config.meta.ogType,
            }, {
                name: "og:site_name",
                content: $t(`meta.og.siteName`),
            },
            // Mutable og:tags
            {
                vmid: "og:url",
                name: "og:url",
                property: "og:url",
                content: config.appHostUrl,
            }, {
                vmid: "og:title",
                name: "og:title",
                property: "og:title",
                content: $t(`meta.og.title`),
            }, {
                vmid: "og:description",
                name: "og:description",
                property: "og:description",
                content: $t(`meta.og.description`),
            }, {
                vmid: "og:image",
                name: "og:image",
                property: "og:image",
                content: `${config.awsCloudFrontUrl}/images/login-background.png`,
            }, {
                vmid: "og:image:height",
                name: "og:image:height",
                property: "og:image:height",
                content: config.meta.ogImageHeight,
            }, {
                vmid: "og:image:width",
                name: "og:image:width",
                property: "og:image:width",
                content: config.meta.ogImageWidth,
            },
            // Twitter specific
            {
                vmid: "twitter:card",
                name: "twitter:card",
                property: "twitter:card",
                content: config.meta.twitterCard,
            }, {
                vmid: "twitter:site",
                name: "twitter:site",
                property: "twitter:site",
                content: config.meta.twitterSite,
            }],
            link: [{
                rel: "manifest",
                href: `/manifest.json`,
            }, {
                rel: "icon",
                type: "image/png",
                sizes: "32x32",
                href: `${config.awsCloudFrontUrl}${config.favicon32x32}`,
            }, {
                rel: "icon",
                type: "image/png",
                sizes: "16x16",
                href: `${config.awsCloudFrontUrl}${config.favicon16x16}`,
            }, {
                rel: "apple-touch-icon",
                href: `${assetPath}/icons/apple-touch-icon-152x152.png`,
            }, {
                rel: "apple-touch-icon",
                href: `${assetPath}/icons/apple-touch-icon-48x48.png`,
            }, {
                rel: "apple-touch-icon",
                sizes: "72x72",
                href: `${assetPath}/icons/apple-touch-icon-72x72.png`,
            }, {
                rel: "apple-touch-icon",
                sizes: "96x96",
                href: `${assetPath}/icons/apple-touch-icon-96x96.png`,
            }, {
                rel: "apple-touch-icon",
                sizes: "144x144",
                href: `${assetPath}/icons/apple-touch-icon-144x144.png`,
            }, {
                rel: "apple-touch-icon",
                sizes: "192x192",
                href: `${assetPath}/icons/apple-touch-icon-192x192.png`,
            }, {
                rel: "mask-icon",
                color: "#4447b2",
                href: `${assetPath}/icons/safari-pinned-tab.svg`,
            }],
        };
    },
    data: function () {
        return {
            router: this.$route,
        };
    },
    computed: {
        key: function () {
            const isProjectEditor = this.$router?.currentRoute?.value?.matched?.some(
                record => record.meta.isProjectEditor
            );
            if (isProjectEditor) return this.$route.params.projectId;

            const isQuestionnaireEditor = this.$router?.currentRoute?.value?.matched?.some(
                record => record.meta.isQuestionnaireEditor
            );
            if (isQuestionnaireEditor) return this.$route.params.questionnaireId;

            return this.$route.path;
        },
        customerSurveyId: function(){
            return config?.customerSurveyId || undefined;
        },
        bannerLink: function () {
            return config?.bannerLink || undefined;
        },
        bannerId: function () {
            return config?.bannerId || undefined;
        },
        bannerItems: function(){
            const banneritems = [];
            if (this.bannerLink) {
                banneritems.push({   
                    id: this.bannerId,
                    message: `bannerLink.message`, 
                    link: this.bannerLink, 
                    storeLocation: "doNotShowBannerLinkAgain", 
                    state: "bannerLinkWasShown",
                });
            }

            if (this.customerSurveyId) {
                const route = this.$router.resolve({
                    name: "Respond",
                    params: { questionnaireId: this.customerSurveyId },
                    query: { lang: this.$store.state.editorLocale || this.$store.state.locale },
                });
                const surveyHref = route.href;
                banneritems.push({   
                    id: this.customerSurveyId,
                    message: `customerSurvey.message`, 
                    link: surveyHref, 
                    storeLocation: "doNotShowSurveyLinkAgain", 
                    state: "surveyLinkWasShown",
                });
            }
            return banneritems.filter(
                (item) => this.showLink(item.id)
            );
        },
    },
    methods: {
        showBrowserWarning: function () {
            if (
                config.showBrowserWarning &&
                this.$isDepreciatedBrowser && 
                !this.$store.state.browserWarningWasShown
            ) {
                this.$oruga.modal.open({
                    destroyOnHide: true,
                    component: BrowserWarningPopup,
                    hasModalCard: true,
                    trapFocus: true,
                    events: {
                        "close": () => {
                            this.$store.commit("browserWarningWasShown");
                        },
                    },
                });
            }
        },
        closeLink: function (state) {
            this.$store.commit(state);
        },
        doNotShowLinkAgain: function (storeLocation, id) {
            this.$store.commit(storeLocation, id);
        },
        showLink: function (linkType) {
            let wasShown;
            if(linkType === this.customerSurveyId) {
                wasShown = (
                    this.$store.state.surveyLinkWasShown ||
                    localStorage.getItem(`do-not-show-survey-id-${this.customerSurveyId}`)
                );
            } else if (linkType === this.bannerId) {
                wasShown = (
                    this.$store.state.bannerLinkWasShown ||
                    localStorage.getItem(`do-not-show-banner-${this.bannerId}`)
                );
            }
            
            const routeName = this.$route.name;

            const isCorrectRoute = (
                routeName === "PostBrowser" ||
                routeName === "GroupBrowser" ||
                routeName === "AssetBrowser" ||
                routeName === "QuestionnaireBrowser"
            );

            return (
                !wasShown &&
                isCorrectRoute &&
                linkType
            );
        },
    },
};
</script>

<style lang="scss">
    // Import scss
    @import "@/styles/index.scss";

    // Gloal app settings
    #app {
        font-family: #{$font-family};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: $text-color-dark;
    }

    // Support for apple's dynamic system font size
    // ARGRGRG Safari on macOS started to support this, meaning that text size is all 
    // messed up on desktop. Need to figure out a reasonable "@support not" or other way 
    // of limiting this to mobile (max size won't do since iPhones are too wide, also 
    // supporting landscape gets tricky)
    // @supports (font: -apple-system-body) {
    //     html {
    //         font: -apple-system-body;
    //     }
    // }

    @media print {
        html, body {
            ::-webkit-scrollbar {
                display: none;
            }
        } 
    }

    label.label {
        margin-bottom: 0.5rem;
    }
    legend.label {
        display: block;
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }

    // Survey notification relates CSS
    .mpt-survey-notification {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: space-between;
        height: $public-menu-height + 10px;
        background: #ffd6af;
    }
    .mpt-survey-notification .mpt-message {
        display: flex;
        flex-direction: column;
    }
    .mpt-survey-notification .mpt-message a {
        font-weight: 800;
        text-decoration: underline;
    }
    .mpt-survey-notification .mpt-do-not-button {
        margin: 3px auto 0 auto;
        padding: 0;
        height: 1rem;
    }
    .mpt-survey-notification button {
        margin: 0 5px;
    }
    .mpt-survey-notification .button.mpt-do-not-button:hover {
        background: transparent;
    }
    .mpt-survey-notification .button.mpt-close-button:hover {
        background: rgba(0,0,0,0.1);
    }

    // Global overrides for Bulma elements

    // Hide right-hand vertical scrollbar added by bulma
    // NOTE NOTE NOTE NOTE
    // This was inducing horrible, horrible map scrolling behavior for the map container
    // TODO re-check if bulma scrollbars are present, and is yes, figure out a better way
    // to disable them.
    // html, body {
    //     overflow-y: unset; 
    // }

    [dir="rtl"] .b-checkbox.checkbox .control-label {
        padding-right: calc(0.75em - 1px);
        padding-left: unset;
    }

    [dir="rtl"] .field.is-grouped > .control  {
        margin-right: unset;
        margin-left: 0.75em;
    }

    [dir="rtl"] .control-label  {
        padding-left: unset;
        padding-right: calc(0.75em - 1px);
    }

    [dir="rtl"] .slider-label-right, .range-label-right  {
        margin-left: unset;    
        margin-right: 15px;
    }
    [dir="rtl"] .mpt-button-icon.fa-layers  {
        margin-left: unset;    
        margin-right: 20px;
    }


    // makes table paginators nicer 
    .pagination.is-simple {
        padding-right: 20px;
    }

    .dialog .modal-card-body ul {
        list-style: unset;
        padding: 0px 0px 0px 30px;
    }

    // Make the active tab indicator nicer
    .tabs li a,
    .tabs li.is-active a {
        border-bottom-width: 2px;
    }
    .tabs li a {
        border-bottom-color: $transparent-color;
    }
    .tabs li a:hover {
        border-bottom-color: $brand-blue-transparent-color;
    }
    .tabs li {
        padding: 0px 20px 0px 20px;
    }
    .tabs a {
        padding-right: 0px;
        padding-left: 0px;
    }
    // Make the notification nicer
    .notices .snackbar {
        background: white;
        color: $grey-20-color;
        border: 1px solid $grey-85-color;
    }

    // Make selected table row not use primary color
    .table tr.is-selected {
        background-color: $grey-90-color;
        color: $grey-20-color;
    }

    // Fix buefy active tab focus
    .tab-item:focus {
        outline: none;
    }
    [role=tabpanel]:focus { 
        outline: none; 
    }
</style>