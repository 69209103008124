import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/post/comment/get": {
        required: ["commentId", "postId"],
    },
    "/post/comment/create": {
        required: ["content", "postId", "publicationId"],
    },
    "/post/comment/list": {
        required: ["postId"],
        listResponse: "comments",
        attachToResponseItems: ["postId"],
    },
    "/post/comment/disable": {
        required: ["commentId", "postId"],
    },
    "/post/comment/restore": {
        required: ["commentId", "postId"],
    },
    "/post/comment/destroy": {
        required: ["commentId", "postId"],
    },  
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/post/comment/get", args),
    list: (options, args) => apiRequest(options, "/post/comment/list", args),
    create: (options, args) => apiRequest(options, "/post/comment/create", args),
    disable: (options, args) => apiRequest(options, "/post/comment/disable", args),
    restore: (options, args) => apiRequest(options, "/post/comment/restore", args),
    destroy: (options, args) => apiRequest(options, "/post/comment/destroy", args),
};
