import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/search/global": {
        required: [],
        listResponse: "users",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    global: (options, args) => apiRequest(options, "/user/search/global", args), 
};
