import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/participation/project/vote/anonymous/add": {
        required: ["projectId", "content"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    add: (options) => apiRequest(options, "/participation/project/vote/anonymous/add"),
};