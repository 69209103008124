import Vue from "vue";
import Vuex, { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import auth from "./modules/auth";
import admin from "./modules/admin";
import editor from "./modules/editor";
import browser from "./modules/browser";
import respond from "./modules/respond";
import analysis from "./modules/analysis";

// Vue.use(Vuex);

const state = {
    initialized: null,
    user: {},
    // Store project votes by projectId
    votes: {},
    access: {},
    licenses: {},
    settings: {},
    hasGroups: null,
    activeGroupId: null,
    activeGroupName: null,
    activeGroupAccess: null,
    pendingInvitations: null,
    // groupObjectCache: {},
    // groupUsageStatistics: {},
    recentGroups: [],
    locale: null,
    rtl: null,
    editorLocale: null,
    editorRtl: null,
    browserWarningWasShown: localStorage.getItem("do-not-show-browser-warning") || false,
    surveyLinkWasShown: false,
    bannerLinkWasShown: false,
    // currentMapView holds the most recent map view at all times. It is updated by e.g.
    // the OL map's view's on:changed hook
    currentMapView: {},
    // initialMapView holds the view that was passed via URL query params. The view has
    // an additional pageId key that holds the pageId for which the view was set. This
    // should be the first OL based page in the questionnaire, but for future proofing
    // we record the pageId here. 
    initialMapView: {},
    // a boolean flag to indicate that a superuser wants to skip licensing checks
    superuserLicenseOverride: false,
};

// const store = new Vuex.Store({
//     strict: true,
//     state,
//     actions,
//     getters,
//     mutations,
//     modules: {auth, admin, editor, browser, respond, analysis},
//     plugins: [createPersistedState({
//         paths: [
//             "rtl",
//             "locale",
//             "licenses",
//             "editorRtl",
//             "editorLocale",
//             "user.userId",
//             "recentGroups",
//             "activeGroupId",
//             "activeGroupName",
//             "activeGroupAccess",
//             // "groupUsageStatistics",
//             "respond.respondentIds",
//         ],
//     })],

// });


const store = createStore({
    strict: true,
    state,
    actions,
    getters,
    mutations,
    modules: {auth, admin, editor, browser, respond, analysis},
    plugins: [createPersistedState({
        paths: [
            "rtl",
            "locale",
            "licenses",
            "editorRtl",
            "editorLocale",
            "user.userId",
            "recentGroups",
            "activeGroupId",
            "activeGroupName",
            "activeGroupAccess",
            // "groupUsageStatistics",
            "respond.respondentIds",
        ],
    })],
});

if (module.hot) {
    module.hot.accept([
        "./getters",
        "./actions",
        "./mutations",
        "./modules/auth/getters",
        "./modules/auth/actions",
        "./modules/auth/mutations",
        "./modules/admin/getters",
        "./modules/admin/actions",
        "./modules/admin/mutations",
        "./modules/editor/getters",
        "./modules/editor/actions",
        "./modules/editor/mutations",
        "./modules/browser/getters",
        "./modules/browser/actions",
        "./modules/browser/mutations",
        "./modules/respond/getters",
        "./modules/respond/actions",
        "./modules/respond/mutations",
        "./modules/analysis/getters",
        "./modules/analysis/actions",
        "./modules/analysis/mutations",
    ], () => {
        store.hotUpdate({
            getters: require("./getters").default,
            actions: require("./actions").default,
            mutations: require("./mutations").default,
            modules: {
                auth: require("./modules/auth").default,
                admin: require("./modules/admin").default,
                editor: require("./modules/editor").default,
                browser: require("./modules/browser").default,
                respond: require("./modules/respond").default,
                analysis: require("./modules/analysis").default,
            },
        });
    });
}

export default store;