import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/questionnaire/comment/get": {
        required: ["commentId", "questionnaireId"],
    },
    "/questionnaire/comment/create": {
        required: ["content", "questionnaireId", "publicationId", "sectionName"],
    },
    "/questionnaire/comment/list": {
        required: ["questionnaireId"],
        listResponse: "comments",
        attachToResponseItems: ["questionnaireId"],
    },
    "/questionnaire/comment/disable": {
        required: ["commentId", "questionnaireId"],
    },
    "/questionnaire/comment/restore": {
        required: ["commentId", "questionnaireId"],
    },
    "/questionnaire/comment/destroy": {
        required: ["commentId", "questionnaireId"],
    },  
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/questionnaire/comment/get", args),
    list: (options, args) => apiRequest(options, "/questionnaire/comment/list", args),
    create: (options, args) => apiRequest(options, "/questionnaire/comment/create", args),
    disable: (options, args) => apiRequest(options, "/questionnaire/comment/disable", args),
    restore: (options, args) => apiRequest(options, "/questionnaire/comment/restore", args),
    destroy: (options, args) => apiRequest(options, "/questionnaire/comment/destroy", args),
};
