import { apiRequestFactory } from "@/api/util";
import { PostPropertyMap } from "@/../../server/src/shared/webdist/postProperties";

const apiSpec = {
    "/post/get": {
        required: ["postId"],
    },
    "/post/list": {
        required: ["ownerGroupId"],
        listResponse: "posts",
        attachToResponseItems: ["ownerGroupId"],
    },
    "/post/create": {
        required: ["ownerGroupId"],
    },
    "/post/clone": {
        required: ["clonePostId", "ownerGroupId", "title"],
    },
    "/post/update": {
        required: ["postId"],
    },
    "/post/transfer": {
        required: ["postId", "ownerGroupId"],
    },
    "/post/disable": {
        required: ["postId"],
        booleanResponse: "disabled",
    },
    "/post/restore": {
        required: ["postId"],
        booleanResponse: "restored",
    },
    "/post/destroy": {
        required: ["postId"],
        booleanResponse: "destroyed",
    },
};
const typeSpec = {
    post: {
        properties: PostPropertyMap, 
    },
};
const apiRequest = apiRequestFactory(apiSpec, typeSpec, null, "post");

export default {
    get: (options, args) => apiRequest(options, "/post/get", args),
    list: (options, args) => apiRequest(options, "/post/list", args),
    clone: (options, args) => apiRequest(options, "/post/clone", args),
    create: (options, args) => apiRequest(options, "/post/create", args),
    update: (options, args) => apiRequest(options, "/post/update", args),
    transfer: (options, args) => apiRequest(options, "/post/transfer", args),
    disable: (options, args) => apiRequest(options, "/post/disable", args),
    restore: (options, args) => apiRequest(options, "/post/restore", args),
    destroy: (options, args) => apiRequest(options, "/post/destroy", args),
};
