import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/get": {
        required: ["userId"],
    },
    "/user/search": {
        listResponse: "users",
    },
    "/user/connect": {
        required: ["userId"],
        boolean: "connected",
    },
    "/user/disconnect": {
        required: ["userId"],
        boolean: "disconnected",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/user/get", args),
    search: (options, args) => apiRequest(options, "/user/search", args),
    connect: (options, args) => apiRequest(options, "/user/connect", args),
    disconnect: (options, args) => apiRequest(options, "/user/disconnect", args),    
};
