import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/self/access/get": {
        required: ["groupId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/user/self/access/get", args),
};
