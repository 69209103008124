import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/participation/project/vote/self/add": {
        required: ["projectId", "content"],
    },
    "/participation/project/vote/self/get": {
        required: ["projectId"],
    },
    "/participation/project/vote/self/update": {
        required: ["projectId"],
    },
    "/participation/project/vote/self/destroy": {
        required: ["projectId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    add: (options) => apiRequest(options, "/participation/project/vote/self/add"),
    get: (options) => apiRequest(options, "/participation/project/vote/self/get"),
    update: (options) => apiRequest(options, "/participation/project/vote/self/update"),
    destroy: (options) => apiRequest(options, "/participation/project/vote/self/destroy"),
};