import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/group/license/add": {
        required: ["groupId"],
        listResponse: "licenseProperties",
        attachToResponseItems: ["eventId"],
    },
    "/user/group/license/view": {
        required: ["groupId"],
    },

    "/user/group/license/expire": {
        required: ["eventId", "expiredTime", "groupId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    add: (options, args) => apiRequest(options, "/user/group/license/add", args),
    view: (options, args) => apiRequest(options, "/user/group/license/view", args),
    expire: (options, args) => apiRequest(options, "/user/group/license/expire", args),    
};
