import { apiRequestFactory } from "@/api/util";
import { ProposalPropertyMap } from "@/../../server/src/shared/webdist/proposalProperties";

const apiSpec = {
    "/participation/proposal/get": {
        required: ["projectId", "proposalId"],
    },
    "/participation/proposal/view": {
        required: ["projectId", "proposalId"],
    },
    "/participation/proposal/list": {
        required: ["projectId"],
        listResponse: "proposals",
        attachToResponseItems: ["projectId"],
    },
    "/participation/proposal/create": {
        required: ["projectId", "name"],
    },
    "/participation/proposal/update": {
        required: ["projectId", "proposalId"],
    },
    "/participation/proposal/disable": {
        required: ["projectId", "proposalId"],
        booleanResponse: "disabled",
    },
    "/participation/proposal/restore": {
        required: ["projectId", "proposalId"],
        booleanResponse: "restored",
    },
};
const typeSpec = {
    proposal: {
        properties: ProposalPropertyMap, 
    },
};
const apiRequest = apiRequestFactory(apiSpec, typeSpec, null, "proposal");

export default {
    get: (options) => apiRequest(options, "/participation/proposal/get"),
    view: (options) => apiRequest(options, "/participation/proposal/view"),
    list: (options) => apiRequest(options, "/participation/proposal/list"),
    create: (options) => apiRequest(options, "/participation/proposal/create"),
    update: (options) => apiRequest(options, "/participation/proposal/update"),
    disable: (options) => apiRequest(options, "/participation/proposal/disable"),
    restore: (options) => apiRequest(options, "/participation/proposal/restore"),
};