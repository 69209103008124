import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/post/comment/moderation/disable": {
        required: ["commentId", "postId"],
    },
    "/post/comment/moderation/restore": {
        required: ["commentId", "postId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    disable: (options, args) => apiRequest(options, "/post/comment/moderation/disable", args),
    restore: (options, args) => apiRequest(options, "/post/comment/moderation/restore", args),
};
