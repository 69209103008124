import _ from "lodash";

export default {
    // Editor UI state
    selectedPageId: (state, value) => { 
        if (state.selectedPageId !== value) {
            // Keep UI consistent
            state.selectedElementId = null;
            state.showElementPopupBar = false;
            state.showElementSettings = false;
        }
        state.showQuestionnaireSettings = false;
        state.selectedPageId = value; 
    },
    selectedPopupId: (state, value) => { state.selectedPopupId = value; },
    selectedElementId: (state, value) => { 
        if (_.isNil(value)) {
            state.showElementSettings = false;
        }
        state.selectedElementId = value; 
    },
    showElementLibrary: (state, bool) => { state.showElementLibrary = bool; },
    showElementSettings: (state, bool) => { state.showElementSettings = bool; },
    showElementPopupBar: (state, bool) => {
        // Make sure UI is consistent
        if (bool) {
            state.showElementLibrary = false;
            state.showElementSettings = false;            
        }
        state.showElementPopupBar = bool; 
    },
    showQuestionnaireSettings: (state, bool) => {
        // Make sure that UI state is cleaned (should be done explicitly in components)
        if (bool) {
            state.showElementLibrary = false;
            state.showElementSettings = false;
        }
        state.showQuestionnaireSettings = bool;
    },
    resetEditorState: state => {
        state.selectedPageId = null;
        state.selectedPopupId = null;
        state.selectedElementId = null;
        state.showElementLibrary = false;
        state.showElementPopupBar = false;
        state.showElementSettings = false;
        state.showQuestionnaireSettings = false;
    },

    // TODO move to using questionnaire.assets / publication.assets
    addAssetToCache (state, asset) {
        const copy = _.cloneDeep(asset);
        state.assetCache = {...state.assetCache, [copy.assetId]: copy};
    },
};