import _ from "lodash";
import store from "@/store";
import config from "@/config";

import { DateTime } from "luxon";

import superAccessMappings from "@/static/superAccessMappings";

const groupHas = state => (licenseType, groupId) => {
    const isSuperOverride = (
        isSuper(state)("any") &&
        state.superuserLicenseOverride
    );
    if (isSuperOverride) return true;

    if (!groupId) return false;

    const licenseFromConfigFile =
        config?.license?.[groupId]?.includes?.("all") ||
        config?.license?.[groupId]?.includes?.(licenseType) ||
        config?.license?.global?.includes?.(licenseType);
    if (licenseFromConfigFile) return true;

    const license = state?.licenses?.[groupId];

    const notFetched = _.isNil(license);
    if (notFetched) return null;

    const isStale = DateTime.local(license.fetchedTime).diffNow("hours") > 24;
    if (isStale) return null;

    return license?.properties?.[licenseType];
};

const has = state => licenseType => {
    const isSuperOverride = (
        isSuper(state)("any") &&
        state.superuserLicenseOverride
    );
    if (isSuperOverride) return true;

    const groupId = state?.activeGroupId;

    const licenseFromConfigFile =
        config?.license?.[groupId]?.includes?.("all") ||
        config?.license?.[groupId]?.includes?.(licenseType) ||
        config?.license?.global?.includes?.(licenseType);
    if (licenseFromConfigFile) return true;

    const license = state?.licenses?.[groupId];

    const notFetched = _.isNil(license);
    if (notFetched) return null;

    const isStale = DateTime.local(license.fetchedTime).diffNow("hours") > 24;
    if (isStale) return null;

    return license?.properties?.[licenseType];
};

const hasAny = state => explicitGroupId => {
    const isSuperOverride = (
        isSuper(state)("any") &&
        state.superuserLicenseOverride
    );
    if (isSuperOverride) return true;

    const groupId = explicitGroupId || state?.activeGroupId;

    const license = state?.licenses?.[groupId] || { properties: [] };
    
    const isStale = license && DateTime.local(license.fetchedTime).diffNow("hours") > 24;
    if (isStale) {
        store.dispatch("getLicenses", groupId);
    }
        
    const licenseIndex = Object.keys(license.properties).findIndex(key => license.properties[key] === true);
    if (licenseIndex !== -1) return true;

    const hasGlobalLicense = config?.license?.global?.length > 0;
    if (hasGlobalLicense) return true;

    const hasGroupLicense = config?.license?.[groupId]?.length > 0;
    if (hasGroupLicense) return true;

    return false;
};

const isSuper = state => type => {
    if (type === "any") {
        return (
            state?.user?.superuser &&
            !_.isEmpty(state.user.superuser)
        );
    }
    return state?.user?.superuser?.[type] === true;
};

const can = state => accessType => {
    // If you don't have a license of any kind, you can not do anything
    if (!hasAny(state)()) return false;

    const access = state?.access?.[state?.activeGroupId];

    const isStale = access && DateTime.local(access.fetchedTime).diffNow("hours") > 24;
    if (isStale) {
        store.dispatch("getAccessLevels", state?.activeGroupId);
    }

    const types = access?.types || [];
    const index = _.findIndex(types, {
        accessType: accessType,
        isMember: true,
    });
    const hasMembershipBasedAccess = ( index !== -1 );
    return hasMembershipBasedAccess || canSuper(state)(accessType);
};

const canSuper = state => accessType => {
    const neededSuperType = superAccessMappings?.[accessType];
    const hasSuperBasedAccess = state?.user?.superuser?.[neededSuperType] === true;
    return hasSuperBasedAccess;
};

export default {
    can: can,
    has: has,
    hasAny: hasAny,
    groupHas: groupHas,
    isSuper: isSuper,
    canSuper: canSuper,
    dir: state => state.rtl ? "rtl" : "ltr",
    hasAppAccess: state => !!state?.hasGroups,
    hasActiveGroup: state => !!state?.activeGroupId,
    emailVerificationNeeded: (state) => {
        const user = state.user;
        if (user && (user.facebookId || user.googleId || user.hasSuomiFiAuth)) {
            // These auth methods are strong enough, no email verification needed
            return false;
        }
        if (user && user.emailAddress && !user.emailVerified) {
            // Unverified emails may be typoed
            return true;
        }
        return false;
    },
};