import api from "@/api";
import idb from "@/idb";
import log from "@/log";
import router from "@/router";

export default {
    // Request object accepts the same parameters as the /v1/auth/login
    // API endpoint.
    // The returned object has these attributes:
    // sessionId: The authenticated session ID string returned by the server.
    // otpRequired: Whether OTP authentication must occur before the session
    // becomes valid.
    login: (context, request) => {
        return new Promise((resolve, reject) => {
            context.commit("loginRequested");
            if(!request || !request.authMethod) {
                reject("Missing required request data");
                return;
            }

            const endpoints = {
                password: "auth/login",
                google: "auth/google/login",
                facebook: "auth/facebook/login",
            };
            const loginEndpoint = endpoints[request.authMethod];
            if(!loginEndpoint) {
                reject("Invalid authentication method");
                return;
            }

            const { authMethod, ...params } = request;
            api.post(loginEndpoint,
                params
            ).then(response => {
                // Get the session-id and store it in local storage
                // TODO: Don't; rely on the cookie instead
                // Local storage is less secure
                const sessionId = response.data.response.sessionId;
                if(!request.noCookie) {
                    localStorage.setItem("session-id", sessionId);
                }
                // Set auth header defaults for api requests
                api.defaults.headers.common["Authorization"] = (
                    "Bearer " + sessionId
                );
                if (response.data.response.otpEnabled) {
                    // OTP validation still required
                    context.commit("needsOtp", sessionId);
                    resolve({
                        sessionId: sessionId,
                        userDisabled: response.data.response.userDisabled,
                        otpRequired: true,
                    });
                } else {
                    // Update store
                    context.commit("loginSuccess", sessionId);
                    resolve({
                        sessionId: sessionId,
                        userDisabled: response.data.response.userDisabled,
                        otpRequired: false,
                    });
                }
            }).catch(error => {
                // Update store and cleanup local storage session-id
                context.commit("loginError", error);
                localStorage.removeItem("session-id");
                reject(error);
            });
        });
    },
    otp: (context, request) => {
        return new Promise((resolve, reject) => {
            api.post("/auth/otp",
                request
            ).then(response => {
                // TODO: is this `if` ever resolved? `auth/otp` should not return a
                // response that contains `otpEnabled`.
                if (response.data.response && response.data.response.otpEnabled) {
                    router.replace("/auth/otp");
                    resolve();
                }
                // Update store
                context.commit("loginSuccess", null);
                resolve("loginSuccess");
            }).catch(error => {
                // Update store and cleanup local storage session-id
                context.commit("loginError", error);
                localStorage.removeItem("session-id");
                reject(error);
            });
        });
    },
    logout: async (context) => {
        await api.post("/auth/logout").catch(e => {
            log({
                sentry: true,
                message: "store/auth/logout: FAILED to find log out.",
                class: "shit-fan",
            });
        });
        context.commit("logout");
        localStorage.removeItem("session-id");
        delete api.defaults.headers.common["Authorization"];
        idb.delete().catch(log);
    },
    // register: (context, request) => {
    //     return new Promise((resolve, reject) => {
    //         api.post("/auth/register",
    //             request
    //         ).then(resolve).catch(reject);
    //     });
    // },
};