const bus = {
    // Map event names to the callbacks which are associated with them.
    subscribers: {},

    // Subscribe to an event name.
    // Subscribed callbacks are invoked in the order that they were added.
    // Each unique callback is added only once, like addEventListener.
    subscribe: function (eventName, callback) {
        if(typeof(callback) !== "function") {
            throw new Error("Callback must be a function");
        }
        if (this.subscribers[eventName]) {
            if (this.subscribers[eventName].indexOf(callback) < 0) {
                this.subscribers[eventName].push(callback);
            }
        } else {
            this.subscribers[eventName] = [callback];
        }
    },

    // Remove a previously-subscribed callback.
    unsubscribe: function (eventName, callback) {
        const subscribers = this.subscribers[eventName];
        if (!subscribers) return;
        const index = subscribers.indexOf(callback);
        if (index >= 0) subscribers.splice(index, 1);
    },

    // Broadcast an event.
    emit: function (eventName, ...data) {
        const subscribers = this.subscribers[eventName];
        if (!subscribers) return;
        for (const callback of subscribers) callback(...data);
    },
};

export default bus;