// Helper class for properties of questionnaires, elements, assets, etc.
import store from "@/store";

export class Properties {
    // Create a helper object.
    // Defaults should be an object mapping keys to property specification
    // objects. These objects should have at minimum a "default" attribute.
    // The object can be initialized with a map. The map contains language
    // code keys associated with objects themselves mapping property keys
    // to property values. Note the wildcard "*" language-agnostic key
    constructor(propertyInfoMap = null, map = null){
        this.propertyInfoMap = propertyInfoMap;
        this.map = map || {};
    }
    
    // Get a Properties instance given an input map or array
    static from(fromProperties, propertyInfoMap = null){
        if(Array.isArray(fromProperties)){
            const properties = new Properties(propertyInfoMap);
            properties.addList(fromProperties);
            return properties;
        }else if(typeof(fromProperties) === "object"){
            const properties = new Properties(propertyInfoMap);
            properties.addMap(fromProperties);
            return properties;
        }else{
            throw new Error("Input must be an array or an object.");
        }
    }
    
    // Add properties from a list (overwrites old values)
    addList(list) {
        if(!list) return;
        for(const property of list){
            const languageCode = property.languageCode || "*";
            if(!this.map[languageCode]){
                this.map = {
                    ...this.map, 
                    [languageCode]: {},
                };
            }
            this.map[languageCode] = {
                ...this.map[languageCode],
                [property.key]: property.value,
            };
        }
    }
    
    // Merge properties from a map (overwrites old values) 
    addMap(map){
        if(!map) return;
        for(const languageCode in map){
            if(!this.map[languageCode]){
                this.map = {
                    ...this.map, 
                    [languageCode]: {},
                };
            }
            const obj = {};
            for(const key in map[languageCode]){
                obj[key] = map[languageCode][key];
            }
            this.map[languageCode] = {
                ...this.map[languageCode],
                ...obj,
            };
        }
    }
    
    // Check if value for a key exists with an optional language code
    hasValue(key, languageCode = null){
        if(languageCode && this.map[languageCode] && key in this.map[languageCode]){
            // Key is available in exactly the requested language
            return true;
        }else if(this.map["*"] && key in this.map["*"]){
            // Key is available using the wildcard "*" language code
            return true;
        }else if(languageCode && languageCode !== "*"){
            // Caller asked for a specific language and it wasn't there
            return false;
        }else if(this.map["en"] && key in this.map["en"]){
            // Caller didn't specify language and an English value is available
            return true;
        }else{
            // Caller didn't specify a language: look for any language that
            // has defined this value and return true if found
            // When no language defines a value for this key,
            // return false
            let useLanguageCode = null;
            for(const tryLanguageCode in this.map){
                if((!useLanguageCode || tryLanguageCode < useLanguageCode) &&
                    key in this.map[tryLanguageCode]
                ){
                    useLanguageCode = tryLanguageCode;
                }
            }
            if(useLanguageCode){
                return true;
            }else{
                return false;
            }
        }
    }
    
    getValueLocal(key){
        const languageCode = store.state.locale || null;
        return this.getValue(key, languageCode);
    }

    // Get a value for a key with an optional language code
    // Defaults to retreiving a value valid in ANY language
    getValue(key, languageCode = null){
        if(languageCode && this.map[languageCode] && key in this.map[languageCode]){
            // Key is available in exactly the requested language
            return this.map[languageCode][key];
        }else if(this.map["*"] && key in this.map["*"]){
            // Key is available using the wildcard "*" language code
            return this.map["*"][key];
        }else if(languageCode && languageCode !== "*"){
            // Caller asked for a specific language and it wasn't there
            // Return the default value (or undefined if there is no default)
            return this.getDefault(key);
        }else if(this.map["en"] && key in this.map["en"]){
            // Caller didn't specify language and an English value is available
            return this.map["en"][key];
        }else{
            // Caller didn't specify a language: look for any language that
            // has defined this value and return it if found
            // The value from the first language code in alphabetical order
            // is used. (This is arbitrarily chosen - it's only a way to
            // ensure that the fallback choice is deterministic.)
            // When no language defines a value for this key,
            // return the default value (or undefined if there is no default)
            let useLanguageCode = null;
            for(const tryLanguageCode in this.map){
                if((!useLanguageCode || tryLanguageCode < useLanguageCode) &&
                    key in this.map[tryLanguageCode]
                ){
                    useLanguageCode = tryLanguageCode;
                }
            }
            if(useLanguageCode){
                return this.map[useLanguageCode][key];
            }else{
                return this.getDefault(key);
            }
        }
    }
    
    // Get a value for a key with an optional language code
    // Only returns a property value for an exact key + language code match,
    // otherwise returns undefined.
    getValueStrict(key, languageCode) {
        return this.map[languageCode] ? this.map[languageCode][key] : undefined;
    }
    
    // Set value for property
    // TODO: One day this should probably validate that the value is of
    // a correct data type
    setValue(languageCode, key, value) {
        if(this.map[languageCode]) {
            this.map[languageCode] = Object.assign(
                {},
                this.map[languageCode],
                {[key]: value}
            );
        }
        else {
            this.map = Object.assign(
                {},
                this.map,
                {[languageCode]: {[key]: value}}
            );
        }
    }
    
    // Get the infoMap value for a given key
    getInfo(key){
        if(!this.propertyInfoMap || typeof(this.propertyInfoMap) !== "object"){
            return undefined;
        }
        return this.propertyInfoMap[key];
    }

    // Get the language-agnostic default value for a given key
    getDefault(key){
        if(!this.propertyInfoMap || typeof(this.propertyInfoMap) !== "object"){
            return undefined;
        }
        return (
            this.propertyInfoMap[key] &&
            this.propertyInfoMap[key].default
        );
    }
    
    // Get the same properties as a list (to send to the backend API)
    getList(){
        const list = [];
        for(const languageCode in this.map){
            for(const key in this.map[languageCode]){
                list.push({
                    languageCode: languageCode,
                    key: key,
                    value: this.map[languageCode][key],
                });
            }
        }
        return list;
    }

    getListForKey(keyToFind) {
        const list = [];
        for(const languageCode in this.map){
            for(const key in this.map[languageCode]){
                if(key !== keyToFind) continue;
                list.push({
                    languageCode: languageCode,
                    key: key,
                    value: this.map[languageCode][key],
                });
            }
        }
        return list;
    }
}

export default Properties;
