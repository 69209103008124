import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/response/aggregate/get": {
        required: [
            "elementId",
            "questionnaireId",
        ],
        listResponse: "responses",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/response/aggregate/get", args),
};
