import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/group/access/get": {
        required: ["groupId", "userId"],
    },
    "/user/group/access/set": {
        required: ["groupId", "userId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/user/group/access/get", args),
    set: (options, args) => apiRequest(options, "/user/group/access/set", args),
};
