export default {
    loginRequested: (state) => {
        state.status = "loading";
    },
    needsOtp: (state, sessionId) => {
        state.status = "pendingOtp";
        state.sessionId = state.sessionId || sessionId;
    },
    loginSuccess: (state, sessionId) => {
        state.status = "success";
        state.sessionId = state.sessionId || sessionId;
    },
    loginError: (state) => {
        state.status = "error";
    },
    logout: (state) => {
        state.sessionId = "";
    },
};