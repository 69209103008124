import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/questionnaire/element/group/get": {
        required: ["questionnaireId"],
    },
    "/questionnaire/element/group/list": {
        required: ["questionnaireId"],
        listResponse: "groups",
    },
    "/questionnaire/element/group/create": {
        required: ["questionnaireId"],
    },
    "/questionnaire/element/group/update": {
        required: ["groupId", "questionnaireId"],
    },
    "/questionnaire/element/group/disable": {
        required: ["groupId", "questionnaireId"],
        booleanResponse: "disabled",
    },
    "/questionnaire/element/group/restore": {
        required: ["groupId", "questionnaireId"],
        booleanResponse: "restored",
    },
    "/questionnaire/element/group/destroy": {
        required: ["groupId", "questionnaireId"],
        booleanResponse: "destroyed",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/questionnaire/element/group/get", args),
    list: (options, args) => apiRequest(options, "/questionnaire/element/group/list", args),
    create: (options, args) => apiRequest(options, "/questionnaire/element/group/create", args),
    update: (options, args) => apiRequest(options, "/questionnaire/element/group/update", args),
    disable: (options, args) => apiRequest(options, "/questionnaire/element/group/disable", args),
    restore: (options, args) => apiRequest(options, "/questionnaire/element/group/restore", args),
    destroy: (options, args) => apiRequest(options, "/questionnaire/element/group/destroy", args),
};
