import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/response/all/get": {
        required: ["questionnaireId", "respondentId"],
        listResponse: "responses",
        attachToResponseItems: ["questionnaireId", "respondentId"],
    },

};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/response/all/get", args),
};
