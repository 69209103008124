import _ from "lodash";
import log from "@/log";
import store from "@/store";

let DB;
const DB_NAME = "MaptionnaireIndexedDB";
const DB_VERSION = 1;

// Default event handler for error events
const onError = rejectCB => event => {
    log(event?.target?.errorCode ?? "Unknown IDB error");
    rejectCB(event);
};

// Default event handler for success event
const onSuccess = resolveCB => event => {
    resolveCB(event);
};

// Event handler for success event when we want to specify a default value
// for the query's result
const onSuccessWithDefault = (resolveCB, defaultResult) => event => {
    resolveCB(event?.target?.result ?? defaultResult);
};

// Event handler for success event when we want to check for elapsed time against
// a 'timeStamp' property in the query's result
const onSuccessWithTimeout = (resolveCB, timeout) => event => {
    const now = Date.now();
    const timeStamp = event?.target?.result?.timeStamp || 0;
    const diff = (now - timeStamp) / 1000 / 60;
    const isFresh = _.isFinite(+diff) && diff < timeout; 
    resolveCB(isFresh);
};

// ////////////////////////////////////////////////////////////////////////////
//
// Database setup, event handlers and utility
//
// ////////////////////////////////////////////////////////////////////////////
const initDB = async () => new Promise((resolve, reject) => {
    // Early resolve if DB already exists
    if (DB) { resolve(DB); return; }

    // Early reject if IndexedDB is not available
    if (!window.indexedDB) {
        log({
            sentry: true,
            message: "idb/index.js: could not locate IndexedDB",
            class: "shit-fan",
        });
        reject("Could not locate IndexedDB");
        return;
    }

    // Open the DB
    const request = window.indexedDB.open(DB_NAME, DB_VERSION);

    // Error handler
    request.onerror = error => {
        log({
            sentry: true,
            message: "idb/index.js: could not open IndexedDB",
            class: "shit-fan",
        });
        reject(error);
    };

    // Success handler
    request.onsuccess = event => {
        DB = event.target.result;
        resolve(DB);
        // Attach a handler for case where another tab or db deletion initiates 
        // a version change
        DB.onversionchange = function(e) {
            DB.close();
            // newVersion is null if this is a deletion event
            if (e.newVersion) {
                /* eslint no-alert: "off" */
                window.alert("The app has been updated, please reload the page to get the latest changes.");
            }
        };
    };

    // Initialization / upgrade handler
    request.onupgradeneeded = event => {
        DB = event.target.result;
        DB.createObjectStore("Events", { keyPath: "eventId" });
        DB.createObjectStore("Questionnaires", { keyPath: "questionnaireId" });
        DB.createObjectStore("Posts", { keyPath: "postId" });
    };

    // Handler for case where other tabs are connected to an older version of the DB
    request.onblocked = event => {
        /* eslint no-alert: "off" */
        window.alert("Please close all other tabs with this application open");
    };
});

const deleteDB = async () => new Promise((resolve, reject) => {
    const request = window.indexedDB.deleteDatabase(DB_NAME);

    request.onerror = onError(reject);
    request.onsuccess = onSuccess(resolve);
});

const clearDB = async () => {
    if (!DB) return;
    await clearStore("Events");
    await clearStore("Questionnaires");
    await clearStore("Posts");
};

const clearStore = async storeName => new Promise((resolve, reject) => {
    if (!DB) { resolve(); return; }

    const request = DB.transaction(storeName, "readwrite")
                      .objectStore(storeName)
                      .clear();

    request.onsuccess = onSuccess(resolve);
    request.onerror = onError(reject);
});


// ////////////////////////////////////////////////////////////////////////////
//
// Promise wrappers for table gets and upserts
//
// ////////////////////////////////////////////////////////////////////////////
const putEvent = eventName => new Promise((resolve, reject) => {
    // Silent fail if DB is not ready.
    if (!DB) { reject(); return; }

    const groupId = store.state.activeGroupId;
    const eventId = `${groupId}:${eventName}`;
    const timeStamp = Date.now();

    if (!groupId || !eventName) { reject(); return; }

    // Set up query
    const query = DB.transaction("Events", "readwrite")
                    .objectStore("Events")
                    .put({ eventId, timeStamp });

    query.onsuccess = onSuccess(resolve);
    query.onerror = onError(reject);
});

const putQuestionnaire = questionnaire => new Promise((resolve, reject) => {
    // Silent fail if DB is not ready.
    if (!DB) { reject(); return; }

    // Set up query
    const query = DB.transaction("Questionnaires", "readwrite")
                    .objectStore("Questionnaires")
                    .put(questionnaire);

    query.onsuccess = onSuccess(resolve);
    query.onerror = onError(reject);
});

const getQuestionnaire = async questionnaireId => new Promise((resolve, reject) => {
    // Silent fail if DB is not ready.
    if (!DB) { resolve({}); return; }

    // Set up query
    const query = DB.transaction("Questionnaires", "readonly")
                    .objectStore("Questionnaires")
                    .get(questionnaireId);

    query.onsuccess = onSuccessWithDefault(resolve, {});
    query.onerror = onError(reject);
});

const isFreshQuestionnaire = async => new Promise((resolve, reject) => {
    // Silent fail if DB is not ready.
    if (!DB) { reject(); return; }

    const groupId = store.state.activeGroupId;
    const eventId = `${groupId}:questionnaireCountsFetched`;

    // Set up query
    const query = DB.transaction("Events", "readonly")
                    .objectStore("Events")
                    .get(eventId);

    query.onsuccess = onSuccessWithTimeout(resolve, 5);
    query.onerror = onError(reject);
});

const putPost = post => new Promise((resolve, reject) => {
    // Silent fail if DB is not ready.
    if (!DB) { reject(); return; }

    // Set up query
    const query = DB.transaction("Posts", "readwrite")
                    .objectStore("Posts")
                    .put(post);

    query.onsuccess = onSuccess(resolve);
    query.onerror = onError(reject);
});

const getPost = async postId => new Promise((resolve, reject) => {
    // Silent fail if DB is not ready.
    if (!DB) { resolve({}); return; }

    // Set up query
    const query = DB.transaction("Posts", "readonly")
                    .objectStore("Posts")
                    .get(postId);

    query.onsuccess = onSuccessWithDefault(resolve, {});
    query.onerror = onError(reject);
});

const isFreshPost = async => new Promise((resolve, reject) => {
    // Silent fail if DB is not ready.
    if (!DB) { reject(); return; }

    const groupId = store.state.activeGroupId;
    const eventId = `${groupId}:postCountsFetched`;

    // Set up query
    const query = DB.transaction("Events", "readonly")
                    .objectStore("Events")
                    .get(eventId);

    query.onsuccess = onSuccessWithTimeout(resolve, 5);
    query.onerror = onError(reject);
});

// ////////////////////////////////////////////////////////////////////////////
//
// Export structured object for getters / setters 
//
// ////////////////////////////////////////////////////////////////////////////
export default {
    init: initDB,
    clear: clearDB,
    delete: deleteDB,
    event: {
        put: putEvent,
    },
    questionnaire: {
        put: putQuestionnaire,
        get: getQuestionnaire,
        isFresh: isFreshQuestionnaire,
    },
    post: {
        put: putPost,
        get: getPost,
        isFresh: isFreshPost,
    },
};