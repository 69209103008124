import { apiRequestFactory } from "@/api/util";


const apiSpec = {
    "/post/variant/group/merge": {
        required: ["groupIds", "ownerGroupId"],
    },
    "/post/variant/group/get": {
        required: ["groupId", "ownerGroupId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    merge: (options) => apiRequest(options, "/post/variant/group/merge"),
    get: (options) => apiRequest(options, "/post/variant/group/get"),  
};
