import axios from "axios";
// See https://stackoverflow.com/
// questions/40941118/axios-wont-send-cookie-ajax-xhrfields-does-just-fine

import { Properties } from "@/store/util";

import _ from "lodash";

import config from "@/config";

// ///////////////////////////////////////////////////////////////////////////////////////
//
// Base api
//
// ///////////////////////////////////////////////////////////////////////////////////////
// Set default authorization headers for all requests if we have a sessionId
const sessionId = localStorage.getItem("session-id");
if (sessionId) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + sessionId;
}
const API_HOST = config.apiHostUrl;
const axiosOptions = {
    baseURL: (typeof (API_HOST) === "undefined" ? "http://localhost:8888" : API_HOST) + "/v1",
};
export const api = axios.create(axiosOptions);

// ///////////////////////////////////////////////////////////////////////////////////////
//
// Helper function
//
// ///////////////////////////////////////////////////////////////////////////////////////
// Validate 401 response as valid (i.e. validate axios default + 401)
export function allow401 (status) {
    return (status >= 200 && status < 300) || status === 401; 
}
// Generic API response handler that ensures properties are well formed
export function apiRequestFactory (apiSpec, TypeMap, variableTypeName, singularTypeName, TypeMaps) {
    return async (options, path, args) => {
        // Check that all required request parameters are present

        if (_.find(apiSpec?.[path]?.required, k => !(k in options))){
            return Promise.reject(`Incorrect options for ${path} request: ${JSON.stringify(options)}`.slice(0, 5000));
        }
        // Make API call
        let r;
        try {
            r = await api.post(path, options, args);
        } catch (e) {
            return Promise.reject(e);
        }
        // Some endpoints return pure data such as csv/pdf/excel files 
        if (apiSpec?.[path]?.isDataResponse) {
            return Promise.resolve(r?.data);
        }
        const response = r?.data?.response;
        if (!response) return Promise.reject(`Unknown response for ${path}`);

        // Disable, restore and destroy endpoints only give a yes/no answer back
        if (apiSpec?.[path]?.booleanResponse) {
            return Promise.resolve(response?.[apiSpec?.[path]?.booleanResponse]);
        }

        // For some list responses we do post processing of list items
        const listPath = apiSpec?.[path]?.listResponse;
        const attachKey = apiSpec?.[path]?.attachToResponseItems; 
        if (listPath && Array.isArray(attachKey)) {
            _.forEach(attachKey, aKey => {
                _.forEach(response?.[listPath], item => {
                    item[aKey] = response?.[aKey];
                });
            });
        }

        // Most endpoints do not need special processing
        if (_.isNil(TypeMap)){
            return Promise.resolve(response);
        }

        /*
        This is where it gets complicated. :-)

        The response object can have "properties" in various locations that we need to all
        traverse and convert to Property instances. To do this we need the corresponding 
        TypeMap for the properties. The three places where properties can reside in a 
        response object are"

        response = {
            // normally just the top level has properties
            properties: [<main response TypeMap>],
            // sometimes there is a responseContent object that has properties
            [responseContent]: {
                properties: [<main response TypeMap>],
                // and finally the resposneContent object may have one or more 
                // locations that contain lists of objects that have properties
                // The locations and corresponding property types are defined by
                // the TypeMaps array
                [TypeMaps[0].location]: [{
                    properties: [<spec from TypeMaps[0].TypeMap>]
                }],
                [TypeMaps[1].location]: ...
            },
        
            // aaaand if the response contains a list of response objects, we need to 
            // traverse all of those in a similar manner
            [listResponse]: [{ same as above },  ...],
        }
        */

        const contentPath = apiSpec?.[path]?.responseContent;
        // Traverse the main response object
        traverseResponseObject(response, contentPath, TypeMap, variableTypeName, singularTypeName, TypeMaps);
        // If the response if a list of responses
        if (listPath) {
            _.forEach(response?.[listPath], responseItem => {
                if (attachKey) {  
                    responseItem[attachKey] = response?.[attachKey];
                }
                traverseResponseObject(responseItem, contentPath, TypeMap, variableTypeName, singularTypeName, TypeMaps);       
            });
        }
        return Promise.resolve(response);
    };
}

function traverseResponseObject (response, contentPath, TypeMap, variableTypeName, singularTypeName, TypeMaps) {
    // Main response object
    setProperties(response, TypeMap, variableTypeName, singularTypeName);
    // Traverse "locations" as defined in TypeMap if present
    _.forEach(TypeMaps, tm => {
        _.forEach(response?.[tm?.location], locationItem => {
            setProperties(locationItem, tm?.TypeMap, tm?.variableTypeName, tm?.singularTypeName);
        });
    });
    // If "responseContent" is specified
    if (contentPath && response?.[contentPath]) {
        // Main response object
        setProperties(response?.[contentPath], TypeMap, variableTypeName, singularTypeName);
        // Traverse "locations" as defined in TypeMaps if present
        _.forEach(TypeMaps, tm => {
            _.forEach(response?.[contentPath]?.[tm?.location], locationItem => {
                setProperties(locationItem, tm?.TypeMap, tm?.variableTypeName, tm?.singularTypeName);
            });
        });
    }
}


function setProperties (object, TypeMap, variableTypeName, singularTypeName) {
    if(object?.properties){
        let typeName;
        if (singularTypeName){
            typeName = singularTypeName;
        }
        else if (variableTypeName){
            typeName = object?.[variableTypeName];
        }
        if (typeName && TypeMap[typeName]) {
            object.properties = Properties.from(
                object?.properties, 
                TypeMap[typeName]?.properties
            );
        }
    }
    else if(object?.unitProperties){
        let typeName;
        if (singularTypeName){
            typeName = singularTypeName;
        }
        else if (variableTypeName){
            typeName = object?.[variableTypeName];
        }
        if (typeName && TypeMap[typeName]) {
            object.unitProperties = Properties.from(
                object?.unitProperties, 
                TypeMap[typeName]?.unitProperties
            );
        }
    }
}
