import api from "@/api";
import idb from "@/idb";
import store from "@/store";
import config from "@/config";
import { log, sentry } from "@/log";
import { setLanguageAsync, loadLanguageAsync } from "@/i18n";

export async function initGuard (to, from, next) {
    if (!store.state.initialized) {
        await store.dispatch("initApp");
    }
    return next();
}

export function languageGuard (to, from, next) {
    const editorLocale = store?.state?.editorLocale;
    // For routes that can be in any language, do nothing
    if (to.matched.some(record => record.meta.isPublic)) {
        return next();
    }
    // For routes that are previews, only ensure that editor locale is loaded
    if (to.matched.some(record => record.meta.isPreview)) {
        if (editorLocale) loadLanguageAsync(editorLocale);
        return next();
    }
    // At this point we are on an editor page and should adhere to editor locale
    // if present
    if (
        editorLocale && 
        editorLocale !== store?.state?.locale
    ) {
        setLanguageAsync(editorLocale);
    }
    return next();
}

export function authGuard (to, from, next) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters["auth/isAuthenticated"]) {
            // Avoid redirecting to login (should never happen)
            const query = (to.name !== "Login") ? { redirect: to.fullPath } : undefined;
            return next({ path: "/" });
        }
    }
    if (to.matched.some(record => record.meta.requiresUnauth)) {
        if (store.getters["auth/isAuthenticated"]) {
            store.dispatch("auth/logout");
        }
    }
    return next();
}

export function groupGuard (to, from, next) {
    if (to.matched.some(record => record.meta.requiresGroup)) {
        if (!config.accessConfig.allowGroupCreation && !store.state.hasGroups) {
            return next({ path: "/"});
        }   
    }
    return next();
}

export async function idbGuard (to, from, next) {
    if (to.matched.some(record => record.meta.isPublic)) return next();
    await idb.init().catch(log);
    return next();
}

export function disabledGuard (to, from, next) {
    if (store?.state?.user?.disabled && to.name !== "UserDisabled") {
        return next({name: "UserDisabled"});
    }
    return next();
}

export function resetEditorStateGuard(to, from, next) {
    store.commit("editor/resetEditorState");
    return next();
}

export async function forceActiveGroupGuard (to, from, next) {
    // no meta -> pass
    if (!to.meta) {
        return next();
    }
    // no force -> pass
    const forceGroupIdBy = to.meta.forceGroupIdBy;
    if (!forceGroupIdBy) {
        return next();
    }
    // target id and current active id
    let desiredGroupId;
    const currentGroupId = (
        store && 
        store.state && 
        store.state.activeGroupId
    );
    // Do we want to force active group by questionnaire
    if (forceGroupIdBy === "questionnaireId") {
        const desiredQuestionnaireId = to.params["questionnaireId"];
        if (!desiredQuestionnaireId) {
            // Someone is using the forceGroupIdBy meta attribute without the
            // correct route parameter being present.
            sentry("routeGuard: no questionnaire id in path", {
                class: "debug",
            });
            return next();
        }
        const currentQuestionnaire = (
            store &&
            store.state &&
            store.state.editor &&
            store.state.editor.questionnaire
        );
        // Are we in the correct group already?
        if (
            currentQuestionnaire &&
            desiredQuestionnaireId === currentQuestionnaire.questionnaireId &&
            currentGroupId === currentQuestionnaire.ownerGroupId
        ) {
            log("routeGuard: EVERYTHING OK");
            return next();
        }
        // Questionnaire is present, but group is wrong
        else if (
            currentQuestionnaire &&
            desiredQuestionnaireId === currentQuestionnaire.questionnaireId &&
            currentGroupId !== currentQuestionnaire.ownerGroupId
        ) {
            sentry("routeGuard: mis-match between current questionnaire and group", {
                class: "debug",
            });
            desiredGroupId = currentQuestionnaire.ownerGroupId;
        }
        // Correct questionnaire is not present -> fetch and deduce groupId
        else {
            let questionnaireResponse;
            const questionnaireId = desiredQuestionnaireId;
            try {
                questionnaireResponse = await api.post("/questionnaire/get", { questionnaireId });
            } catch (error) {
                // This is likely due to insufficient permissions -> bounce
                log("routeGuard: COULD NOT GET QUESTIONNAIRE");
                return next({ path: "/" });
            }
            desiredGroupId = questionnaireResponse.data.response.ownerGroupId;
        }
    } 
    // or do we want to force active group by post
    else if (forceGroupIdBy === "postId") {
        const postId = to.params["postId"];
        if (!postId) {
            // Someone is using the forceGroupIdBy meta attribute without the
            // correct route parameter being present.
            sentry("routeGuard: no post id in path", {
                class: "debug",
            });
            return next();
        }
        // We can not avoid the server round trip b/c post editor does not use store
        // so no way to access currently loaded post. So, fetch and deduce groupId
        let postResponse;
        try {
            postResponse = await api.post("/post/get", { postId });
        } catch (error) {
            // This is likely due to insufficient permissions -> bounce
            log("routeGuard: COULD NOT GET POST");
            return next({ path: "/" });
        }
        desiredGroupId = postResponse.data.response.ownerGroupId;
    }
    // or do we want to force active group by post
    else if (forceGroupIdBy === "projectId") {
        const projectId = to.params["projectId"];
        if (!projectId) {
            // Someone is using the forceGroupIdBy meta attribute without the
            // correct route parameter being present.
            sentry("routeGuard: no group id in path", {
                class: "debug",
            });
            return next();
        }
        // We can not avoid the server round trip b/c post editor does not use store
        // so no way to access currently loaded post. So, fetch and deduce groupId
        let projectResponse;
        try {
            projectResponse = await api.post("/participation/project/get ", { projectId });
        } catch (error) {
            // This is likely due to insufficient permissions -> bounce
            log("routeGuard: COULD NOT GET POST");
            return next({ path: "/" });
        }
        desiredGroupId = projectResponse.data.response.ownerGroupId;
    }
    // someone is using nonstandard value for forceGroupId.
    else {
        sentry(`routeGuard: unknown ID parameter ${forceGroupIdBy}`, {
            class: "debug",
        });
        return next();
    }

    // So now we (should) have the desired groupId
    if (!desiredGroupId) {
        // Technically no traversal of the above code should lead to this state
        sentry("routeGuard: could not deduce desired group ID", {
            class: "shit-fan",
        });
        return next();
    }

    // Are we already in the correct group?
    if (currentGroupId === desiredGroupId) {
        log("routeGuard: HAD TO DIG AROUND BUT NOW EVERYTHING IS OK");
        return next();
    }

    // Sigh, so we need to actually switch groups
    let groupResponse;
    try {
        groupResponse = await api.post("/user/group/view", { groupId: desiredGroupId });
    } catch (error) {
        sentry("routeGuard: switching group for admin user failed", {
            class: "shit-fan",
        });
        return next(false);
    }
    store.commit("setActiveGroup", groupResponse.data.response);
    await store.dispatch("getLicenses", groupResponse.data.response.groupId);
    await store.dispatch("getAccessLevels", groupResponse.data.response.groupId);
    log("routeGuard: HAD TO ACTUALLY SWITCH GROUPS");
    return next();
}

export default {
    idbGuard,
    initGuard,
    authGuard,
    groupGuard,
    disabledGuard,
    languageGuard,
    forceActiveGroupGuard,
    resetEditorStateGuard,
};