import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/self/membership/list": {
        listResponse: "groups",
    },
    "/user/self/membership/request": {
        required: ["groupId"],
        booleanResponse: "joined",
    },
    "/user/self/membership/remove": {
        required: ["groupId"],
        booleanResponse: "removed",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    list: (options, args) => apiRequest(options, "/user/self/membership/list", args),
    request: (options, args) => apiRequest(options, "/user/self/membership/request", args),
    remove: (options, args) => apiRequest(options, "/user/self/membership/remove", args),
};
