import _ from "lodash";
import * as Sentry from "@sentry/browser";
import { NotificationProgrammatic } from "@oruga-ui/oruga-next";

const devMode = process.env.NODE_ENV === "development" && process.env.VUE_APP_DEVSERVER === "true";

// function for displaying a snackbar after API call errors. 
export function snack(error, options) {
    let e;
    if (typeof error === "string") {
        e = { message: error };
    } else {
        e = (
            error && 
            error.response && 
            error.response.data && 
            error.response.data.error
        ) || error;
    }
    // this will only exists if the error comes from our API
    // message (and thus snack) can be forced by providing message in <options> 
    const message = (options && options.message) || (e && e.message);
    const variant = (options && options.variant) || "info";
    const duration = (options && options.duration) || 5000;
    const queue = (options && options.queue) || false;
    const position = (options && options.position) || "bottom";
    if (message) {
        NotificationProgrammatic.open({
            message,
            rootClass: "toast-notification",
            position: position,
            variant: variant,
            // indefinite: true, // looks like this is deprecated but not sure
            queue: queue,
            duration: duration,
            closable: true,
        });
    }
}

// function for sending sentry events. 
// 
// options.class should be one of the following:
// "shit-fan": for "this should never happen" situations that can cause 
//          real harm such as data loss or broken UI
// "debug": for "this should never happen" situations that are low 
//          impact or benign
// "notification": for situations that are not strictly errors
export function sentry (error, options) {
    let e, o;
    if (error instanceof Error) {
        e = error;
    } else if (typeof error === "string") {
        e = new Error(error);
    } else {
        e = new Error((
                options && 
                options.message
            ) || (
                error && 
                error.response && 
                error.response.data && 
                error.response.data.error
            ) || (
                error && 
                error.message
            )
        );    
    }
    if (options && options.class) {
        o = {
            tags: {
                class: options.class,
            },
        };
    }
    Sentry.captureException(e, o);
}

// function for logging to console when if dev environment
export function toConsole (...args) {
    // eslint-disable-next-line no-console
    if (devMode) console.log(...args);
}

export function timeStart(name) {
    if (devMode) {
        console.timeEnd(name); 
        console.time(name);
    }
}

export function timeLog(name) {
    if (devMode) console.timeLog(name);
}

export function timeEnd(name) {
    if (devMode) console.timeEnd(name);
}

export function warn (...args) {
    // eslint-disable-next-line no-console
    if (devMode) console.warn(...args);
}

// duck typing for options object
function isOptions (obj) {
    return obj && !_.isError(obj) && (
        obj.snack ||
        obj.sentry ||
        obj.message
    );
}

// Generic logging function
//
// function will pipe arguments to console.log if in dev environment 
//
// function supports the following call signatures for specifying <options>
// log(options: Options)
// log(error: Error, options: Options)

// <options> can be used to trigger manual Sentry exception by setting 
// options.sentry: true
// <options> can be used to trigger snackbar by setting 
// options.snack: true
//
export function log (...args) {
    // called via .catch(log(<options>)), returns function that will handle error
    if (
        args.length === 1 && 
        isOptions(args[0])
    ) {
        return (error) => {
            if (args[0].snack) snack(error, args[0]);
            if (args[0].sentry) sentry(error, args[0]);
            if (args[0].message || error.message) {
                toConsole(args[0].message || error.message, error);
            } else {
                toConsole(error);
            }
        };
    } 
    // called directly as log(<error>, <options>)
    else if (
        args.length === 2 &&
        _.isError(args[0]) && 
        isOptions(args[1])
    ) {
        if (args[1].snack) snack(args[0], args[1]);
        if (args[1].sentry) sentry(args[0], args[1]);
        if (args[1].message || args[0].message) {
            toConsole(args[1].message || args[0].message, args[0]);
        } else {
            toConsole(args[0]);
        }
    }
    // called as log(<error>)
    else if (
        args.length === 1 &&
        _.isError(args[0])
    ) {
        // Bypass toConsole development mode check, always log exceptions
        // Hiding exceptions and tracebacks on prod makes debugging a PITA!
        console.log(...args);
    }
    // all other use cases just pipe to console
    else {
        toConsole(...args);
    }
    // Placate linter by returning something
    return true;
}

export default log;