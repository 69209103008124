import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/respondent/list": {
        required: ["questionnaireId"],
        listResponse: "respondents",
        attachToResponseItems: ["questionnaireId"],
    },
    "/respondent/update": {
        required: ["questionnaireId", "respondentId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    list: (options, args) => apiRequest(options, "/respondent/list", args),
    update: (options, args) => apiRequest(options, "/respondent/update", args),
};
