import { api, allow401 } from "@/api/util";

import analyticsApi from "./analytics";

import assetApi from "./asset";

import customer from "./customer";
import customerContact from "./customer/contact";

import questionnaire from "./questionnaire";
import questionnairePublication from "./questionnaire/publication";
import questionnaireElement from "./questionnaire/element";
import questionnaireElementGroup from "./questionnaire/element/group";
import questionnaireResponses from "./questionnaire/responses";
import questionnaireResponseExport from "./questionnaire/response/export";
import questionnaireRespondentExport from "./questionnaire/respondent/export";
import questionnaireComment from "./questionnaire/comment";
import questionnaireCommentModeration from "./questionnaire/comment/moderation";

import post from "./post";
import postElement from "./post/element";
import postPublication from "./post/publication";
import postComment from "./post/comment";
import postVariant from "./post/variant";
import postVariantGroup from "./post/variant/group";
import postCommentModeration from "./post/comment/moderation";

import user from "./user";
import userEmail from "./user/email";
import userMembership from "./user/membership";
import userSelf from "./user/self";
import userSelfAccess from "./user/self/access";
import userSelfMembership from "./user/self/membership";
import userSelfConnections from "./user/self/connections";
import userSearch from "./user/search";
import userGroup from "./user/group";
import userGroupAccess from "./user/group/access";
import userGroupSuper from "./user/group/super";
import userGroupRole from "./user/group/role";
import userGroupLicense from "./user/group/license";
import userGroupLicenseAll from "./user/group/license/all";
import userGroupLicenseCurrent from "./user/group/license/current";
import userGroupSearch from "./user/group/search";
import userGroupMembership from "./user/group/membership";
import userGroupChildren from "./user/group/children";
import userGroupParents from "./user/group/parents";
import userGroupConnections from "./user/group/connections";

import participation from "./participation";
import participationProject from "./participation/project";
import participationProjectCurrency from "./participation/project/currency";
import participationProjectVoteSelf from "./participation/project/vote/self";
import participationProjectVoteAnonymous from "./participation/project/vote/anonymous";
import participationProjectVoteAggregate from "./participation/project/vote/aggregate";

import participationIdea from "./participation/idea";
import participationIdeaPublic from "./participation/idea/public";
import participationIdeaComment from "./participation/idea/comment";
import participationIdeaCommentModeration from "./participation/idea/comment/moderation";

import participationProposal from "./participation/proposal";
import participationProposalCost from "./participation/proposal/cost";
import participationProposalPublic from "./participation/proposal/public";

import respondent from "./respondent";
import respondentSelf from "./respondent/self";

import response from "./response";
import responseAll from "./response/all";
import responseBatch from "./response/batch";
import responsePublic from "./response/public";
import responseAggregate from "./response/aggregate";

const api2 = {
    analytics: analyticsApi,
    asset: assetApi,
    participation: {
        ...participation,
        project: {
            ...participationProject,
            currency: participationProjectCurrency,
            vote: {
                self: participationProjectVoteSelf,
                aggregate: participationProjectVoteAggregate,
                anonymous: participationProjectVoteAnonymous,
            },
        },
        idea: {
            ...participationIdea,
            public: participationIdeaPublic,
            comment: {
                ...participationIdeaComment,
                moderation: participationIdeaCommentModeration,
            },
        },
        proposal: {
            ...participationProposal,
            cost: participationProposalCost,
            public: participationProposalPublic,
        },
    },
    customer: {
        ...customer,
        contact: customerContact,
    },
    post: {
        ...post,
        element: postElement,
        publication: postPublication,
        comment: {
            ...postComment,
            moderation: postCommentModeration,
        },
        variant: {
            ...postVariant,
            group: postVariantGroup,
        },
    },
    questionnaire: {
        ...questionnaire,
        publication: questionnairePublication,
        comment: {
            ...questionnaireComment,
            moderation: questionnaireCommentModeration,
        },
        element: {
            ...questionnaireElement,
            group: questionnaireElementGroup,
        },
        responses: questionnaireResponses,
        response: {
            export: questionnaireResponseExport,
        },
        respondent: {
            export: questionnaireRespondentExport,
        },
    },
    user: {
        ...user,
        email: userEmail,
        membership: userMembership,
        group: {
            ...userGroup,
            access: userGroupAccess,
            children: userGroupChildren,
            connections: userGroupConnections,
            parents: userGroupParents,
            role: userGroupRole,
            search: userGroupSearch,
            membership: userGroupMembership,
            super: userGroupSuper,
            license: {
                ...userGroupLicense,
                all: userGroupLicenseAll,
                current: userGroupLicenseCurrent,
            },
       },
        self: {
            ...userSelf,
            membership: userSelfMembership,
            access: userSelfAccess,
            connections: userSelfConnections,
       },
        search: userSearch,
    },
    respondent: {
        ...respondent,
        self: respondentSelf,
    },
    response: {
        ...response,
        all: responseAll,
        batch: responseBatch,
        public: responsePublic,
        aggregate: responseAggregate,
    },

};

export default api;

export {
    api,
    api2,
    allow401,
};
