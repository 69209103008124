import { apiRequestFactory } from "@/api/util";

import { AssetType } from "@/../../server/src/shared/webdist/assetType";
import { QuestionnairePropertyMap } from "@/../../server/src/shared/webdist/questionnaireProperties";
import { QuestionnaireElementType } from "@/../../server/src/shared/webdist/questionnaireElementType";

const apiSpec = {
    "/questionnaire/publication/get": {
        responseContent: "content",
        required: ["publicationId", "questionnaireId"],
    },
    "/questionnaire/publication/list": {
        responseContent: "previewContent",
        required: ["ownerGroupId"],
        listResponse: "publications",
    },
    "/questionnaire/publication/view": {
        responseContent: "content",
        required: ["questionnaireId"],
    },
    "/questionnaire/publication/create": {
        required: ["questionnaireId"],
    },
    "/questionnaire/publication/preview": {
        responseContent: "content",
        required: ["questionnaireId"],
    },
};

const typeSpec = {
    questionnaire: {
        properties: QuestionnairePropertyMap, 
    },
};

const apiRequest = apiRequestFactory(apiSpec, typeSpec, null, "questionnaire", [{
        location: "assets",
        TypeMap: AssetType.map, 
        variableTypeName: "assetType",
    }, {
        location: "elements",
        TypeMap: QuestionnaireElementType.map,
        variableTypeName: "elementType",
    },
]);

export default {
    get: (options, args) => apiRequest(options, "/questionnaire/publication/get", args),
    list: (options, args) => apiRequest(options, "/questionnaire/publication/list", args),
    view: (options, args) => apiRequest(options, "/questionnaire/publication/view", args),
    create: (options, args) => apiRequest(options, "/questionnaire/publication/create", args),
    preview: (options, args) => apiRequest(options, "/questionnaire/publication/preview", args),
};
