import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/group/membership/list": {
        required: ["groupId"],
        listResponse: "members",
    },
    "/user/group/membership/request": {
        required: ["groupId", "userId"],
        booleanResponse: "joined",
    },
    "/user/group/membership/token/ask": {
        required: ["groupId", "emailAddress", "roleId", "displayName"],
    },
    "/user/group/membership/token/confirm": {
        required: ["tokenId"],
        booleanResponse: "joined",
    },
    "/user/group/membership/remove": {
        required: ["groupId", "userId"],
        booleanResponse: "removed",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    list: (options, args) => apiRequest(options, "/user/group/membership/list", args),
    request: (options, args) => apiRequest(options, "/user/group/membership/request", args),
    tokenAsk: (options, args) => apiRequest(options, "/user/group/membership/token/ask", args),
    tokenConfirm: (options, args) => apiRequest(options, "/user/group/membership/token/confirm", args),
    remove: (options, args) => apiRequest(options, "/user/group/membership/remove", args),
};
