import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/participation/idea/public/list": {
        required: ["projectId"],
        listResponse: "ideas",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    list: (options) => apiRequest(options, "/participation/idea/public/list"),
};