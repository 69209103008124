import { apiRequestFactory } from "@/api/util";
import { PostElementType } from "@/../../server/src/shared/webdist/postElementType";

const apiSpec = {
    "/post/element/get": {
        required: ["elementId", "postId"],
    },
    "/post/element/list": {
        required: ["postId"],
        listResponse: "elements",
    },
    "/post/element/create": {
        required: ["postId", "elementType"],
    },
    "/post/element/update": {
        required: ["postId", "elementId"],
    },
    "/post/element/disable": {
        required: ["elementId", "postId"],
        booleanResponse: ["disabled"],
    },
    "/post/element/restore": {
        required: ["elementId", "postId"],
        booleanResponse: ["restored"],
    },
    "/post/element/destroy": {
        required: ["elementId", "postId"],
        booleanResponse: "destroyed",
    },  
};

const apiRequest = apiRequestFactory(apiSpec, PostElementType.map, "elementType");

export default {
    get: (options, args) => apiRequest(options, "/post/element/get", args),
    list: (options, args) => apiRequest(options, "/post/element/list", args),
    create: (options, args) => apiRequest(options, "/post/element/create", args),
    update: (options, args) => apiRequest(options, "/post/element/update", args),
    disable: (options, args) => apiRequest(options, "/post/element/disable", args),
    restore: (options, args) => apiRequest(options, "/post/element/restore", args),
    destroy: (options, args) => apiRequest(options, "/post/element/destroy", args),
};
