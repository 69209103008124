import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const getDefaultState = () => {
    return {
        activeElementId: null,
        activeLabels: null,
        activePageIndex: 0,
        detailElementId: null,
        popupElementId: null,
        scrollToElementId: null,
        showEditChart: false,
    };
};

const state = getDefaultState();

const editorModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default editorModule;
export { getDefaultState };