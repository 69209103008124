import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/response/public/get": {
        required: [
            "primaryElementId",
            "questionnaireId",
        ],
        listResponse: "respondents",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/response/public/get", args),
};
