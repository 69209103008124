import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/email/get": {
        required: ["emailAddress"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/user/email/get", args),
};
