import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/participation/idea/get": {
        required: ["projectId", "ideaId"],
    },    
    "/participation/idea/view": {
        required: ["ideaId"],
    },
    "/participation/idea/list": {
        required: ["projectId"],
        listResponse: "ideas",
        attachToResponseItems: ["projectId"],
    },
    "/participation/idea/create": {
        required: ["projectId", "questionnaireId", "respondentId"],
    },
    "/participation/idea/update": {
        required: ["projectId", "ideaId"],
    },
    "/participation/idea/disable": {
        required: ["projectId", "ideaId"],
        booleanResponse: "disabled",
    },
    "/participation/idea/restore": {
        required: ["projectId", "ideaId"],
        booleanResponse: "restored",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options) => apiRequest(options, "/participation/idea/get"),
    view: (options) => apiRequest(options, "/participation/idea/view"),
    list: (options) => apiRequest(options, "/participation/idea/list"),
    create: (options) => apiRequest(options, "/participation/idea/create"),
    update: (options) => apiRequest(options, "/participation/idea/update"),
    disable: (options) => apiRequest(options, "/participation/idea/disable"),
    restore: (options) => apiRequest(options, "/participation/idea/restore"),
};