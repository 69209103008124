import { apiRequestFactory } from "@/api/util";
import { AssetType } from "@/../../server/src/shared/webdist/assetType";

const apiSpec = {
    "/asset/get": {
        required: ["assetId"],
    },
    "/asset/list": {
        required: ["ownerGroupId"],
        listResponse: "assets",
        attachToResponseItems: ["ownerGroupId"],
    },
    "/asset/create": {
        required: ["assetType"],
    },
    "/asset/update": {
        required: ["assetId"],
    },
    "/asset/upload": {
        required: ["assetId", "key"],
    },
    "/asset/disable": {
        required: ["assetId"],
        booleanResponse: "disabled",
    },
    "/asset/restore": {
        required: ["assetId"],
        booleanResponse: "restored",
    },
    "/asset/destroy": {
        required: ["assetId"],
        booleanResponse: "destroyed",
    },
};

const apiRequest = apiRequestFactory(apiSpec, AssetType.map, "assetType");

export default {
    get: (options, args) => apiRequest(options, "/asset/get", args),
    list: (options, args) => apiRequest(options, "/asset/list", args),
    create: (options, args) => apiRequest(options, "/asset/create", args),
    update: (options, args) => apiRequest(options, "/asset/update", args),
    upload: (options, args) => apiRequest(options, "/asset/upload", args),
    disable: (options, args) => apiRequest(options, "/asset/disable", args),
    restore: (options, args) => apiRequest(options, "/asset/restore", args),
    destroy: (options, args) => apiRequest(options, "/asset/destroy", args),
};