import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/analytics/questionnaires": {
        required: ["ownerGroupId"],
        listResponse: "questionnaires",
        attachToResponseItems: ["ownerGroupId"],
    },
    // Counts of a single questionnaire
    "/analytics/questionnaire/counts": {
        required: ["ownerGroupId", "questionnaireId"],
    },
    "/analytics/posts": {
        required: ["ownerGroupId"],
        listResponse: "posts",
        attachToResponseItems: ["ownerGroupId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    posts: (options, args) => apiRequest(options, "/analytics/posts", args),
    questionnaires: (options, args) => apiRequest(options, "/analytics/questionnaires", args),
    questionnaireCounts: (options, args) => apiRequest(options, "/analytics/questionnaire/counts", args),
    questionnaireRespondents: (options, args) => apiRequest(options, "/analytics/questionnaires/respondents", args),
};