import { apiRequestFactory } from "@/api/util";

const apiSpec = {

    "/user/group/license/all/get": {
        required: ["groupId"],
        listResponse: ["licenseEvents", "licenseProperties"],
        attachToResponseItems: ["groupId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/user/group/license/all/get", args),
};
