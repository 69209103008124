import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/participation/idea/comment/get": {
        required: ["commentId", "ideaId", "projectId"],
    },    
    "/participation/idea/comment/list": {
        required: ["ideaId", "projectId"],
        listResponse: "comments",
        attachToResponseItems: ["ideaId", "projectId"],
    },
    "/participation/idea/comment/create": {
        required: ["content", "ideaId", "projectId"],
    },
    "/participation/idea/comment/disable": {
        required: ["commentId", "ideaId"],
    },
    "/participation/idea/comment/restore": {
        required: ["commentId", "ideaId"],
    },
    "/participation/idea/comment/destroy": {
        required: ["commentId", "ideaId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options) => apiRequest(options, "/participation/idea/comment/get"),
    list: (options) => apiRequest(options, "/participation/idea/comment/list"),
    create: (options) => apiRequest(options, "/participation/idea/comment/create"),
    disable: (options) => apiRequest(options, "/participation/idea/comment/disable"),
    restore: (options) => apiRequest(options, "/participation/idea/comment/restore"),
    destroy: (options) => apiRequest(options, "/participation/idea/comment/destroy"),
};