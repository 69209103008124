// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!! IMPORTANT !!! IMPORTANT !!! IMPORTANT !!! IMPORTANT !!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//
//     Never, ever, load the private_config file here.
//
import publicConfig from "../../server/public_config.json";
import tenantConfig from "../../server/tenant_config.json";

const config = Object.assign({}, publicConfig, tenantConfig);

export default config;
