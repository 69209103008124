import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/customer/contact/get": {
        required: ["customerId", "contactId"],
    },
    "/customer/contact/create": {
        required: ["customerId"],
    },
    "/customer/contact/search": {
        required: [],
        listResponse: "contacts",
    },
    "/customer/contact/update": {
        required: ["customerId"],
    },
    "/customer/contact/disable": {
        required: ["contactId", "customerId"],
        booleanResponse: "disabled",
    },
    "/customer/contact/restore": {
        required: ["contactId", "customerId"],
        booleanResponse: "restored",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/customer/contact/get", args),
    create: (options, args) => apiRequest(options, "/customer/contact/create", args),
    search: (options, args) => apiRequest(options, "/customer/contact/search", args),
    update: (options, args) => apiRequest(options, "/customer/contact/update", args),
    disable: (options, args) => apiRequest(options, "/customer/contact/disable", args),
    restore: (options, args) => apiRequest(options, "/customer/contact/restore", args),
};
