import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/self/get": {
    },
    "/user/self/update": {
    },
    "/user/self/disable": {
        booleanResponse: "disabled",
    },
    "/user/self/restore": {
        booleanResponse: "restored",
    },
    "/user/self/redact": {
        booleanResponse: "redacted",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/user/self/get", args),
    update: (options, args) => apiRequest(options, "/user/self/update", args),
    disable: (options, args) => apiRequest(options, "/user/self/disable", args),
    restore: (options, args) => apiRequest(options, "/user/self/restore", args),
    destroy: (options, args) => apiRequest(options, "/user/self/redact", args),
};
