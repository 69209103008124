import _ from "lodash";

export default {
    // Analysis tool UI state
    activeElementId: (state, elementId) => { state.activeElementId = elementId; },
    activeLabels: (state, value) => { state.activeLabels = value; },
    activePageIndex: (state, index) => { state.activePageIndex = +index; },
    detailElementId: (state, elementId) => { state.detailElementId = elementId; },
    popupElementId: (state, elementId) => { state.popupElementId = elementId; },
    scrollToElementId: (state, elementId) => { state.scrollToElementId = elementId; },
    showEditChart: (state, bool) => { state.showEditChart = bool; },

};