import { apiRequestFactory } from "@/api/util";
import { QuestionnaireElementType } from "@/../../server/src/shared/webdist/questionnaireElementType";

const apiSpec = {
    "/questionnaire/element/get": {
        required: ["elementId", "questionnaireId"],
    },
    "/questionnaire/element/list": {
        required: ["questionnaireId"],
        listResponse: "elements",
    },
    "/questionnaire/element/create": {
        required: ["elementType", "questionnaireId"],
    },
    "/questionnaire/element/update": {
        required: ["elementId", "questionnaireId"],
    },
    "/questionnaire/element/disable": {
        required: ["elementId", "questionnaireId"],
        booleanResponse: "disabled",
    },
    "/questionnaire/element/restore": {
        required: ["elementId", "questionnaireId"],
        booleanResponse: "restored",
    },
    "/questionnaire/element/destroy": {
        required: ["elementId", "questionnaireId"],
        booleanResponse: "destroyed",
    },
};

const apiRequest = apiRequestFactory(apiSpec, QuestionnaireElementType.map, "elementType");

export default {
    get: (options, args) => apiRequest(options, "/questionnaire/element/get", args),
    list: (options, args) => apiRequest(options, "/questionnaire/element/list", args),
    create: (options, args) => apiRequest(options, "/questionnaire/element/create", args),
    update: (options, args) => apiRequest(options, "/questionnaire/element/update", args),
    disable: (options, args) => apiRequest(options, "/questionnaire/element/disable", args),
    restore: (options, args) => apiRequest(options, "/questionnaire/element/restore", args),
    destroy: (options, args) => apiRequest(options, "/questionnaire/element/destroy", args),
};
