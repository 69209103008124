import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/questionnaire/comment/moderation/disable": {
        required: ["commentId", "questionnaireId"],
    },
    "/questionnaire/comment/moderation/restore": {
        required: ["commentId", "questionnaireId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    disable: (options, args) => apiRequest(options, "/questionnaire/comment/moderation/disable", args),
    restore: (options, args) => apiRequest(options, "/questionnaire/comment/moderation/restore", args),
};
