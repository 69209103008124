import _ from "lodash";
import axios from "axios";
import crypto from "crypto";

import api from "@/api";
import log from "@/log";
import config from "@/config";
import router from "@/router";

let source;

export default {
    initApp: async (context, params) => {
        // If we already did this, do not do it again unless forced to
        if (context.state.initialized && !params?.force) return;

        // Try and get the user object
        let user, groups;
        try {
            user = await api.post("/user/self/get");
        } catch (error) {
            context.commit("clearUser");
            context.dispatch("auth/logout");
        }

        if (user?.data?.response) {
            context.commit("updateUser", user.data.response);
        
            // check if user has any groups
            try {
                const options = {
                    count: true,
                    limit: 1, // <-- fetch 1 so we can switch to it if needed
                };
                groups = await api.post("/user/self/membership/list", options);
            } catch (error) {
                log(error);
            }

            // check if user has pending invites, this can be done async 
            api.post("/user/self/membership/list", {
                count: true,
                limit: 0,
                filter: { 
                    groupAccepted: { is: true },
                    userAccepted: { is: false },
                },
            }).then(response => {
                context.commit("setPendingInvitations", response.data.response.count);
            }).catch(log);
        }

        // check if we got a groups response and set up stuff accordingly
        if (!groups?.data?.response) {
            context.commit("setUserHasGroups", false);
        }
        else {
            const count = groups.data.response.count;
            context.commit("setUserHasGroups", count > 0);

            // for convenience, iff user has 1 group, make it the active group
            const group = groups.data.response.groups[0];
            if (
                count === 1 && 
                groups?.data?.response?.groups?.[0]?.requestAccepted
            ) {
                context.commit("setActiveGroup", group);
                context.dispatch("getLicenses", group.groupId);
                context.dispatch("getAccessLevels", group.groupId);
            } 
            // else check if we have a groupId stored, try to fetch it and set up as 
            // active group
            else if (context.state.activeGroupId) {
                const groupId = context.state.activeGroupId;
                let response;
                try {
                    response = await api.post("/user/group/view", { groupId });
                } catch (error) {
                    context.commit("deactivateActiveGroup");
                }
                const existingGroup = response?.data?.response;
                if (existingGroup) {
                    context.commit("setActiveGroup", existingGroup);
                    context.dispatch("getLicenses", existingGroup.groupId);
                    context.dispatch("getAccessLevels", existingGroup.groupId);
                } else {
                    log("Could not find group");
                    // throw new Error("Group not found");
                }
            }
            // else fall back to recent groups
            else if (
                Array.isArray(context?.state?.recentGroups) && 
                context?.state?.recentGroups?.length > 0
            ) {
                const fallbackGroup = context.state.recentGroups[0];
                context.commit("setActiveGroup", fallbackGroup);
                context.dispatch("getLicenses", fallbackGroup.groupId);
                context.dispatch("getAccessLevels", fallbackGroup.groupId);
            }
        }

        // all done, set flag to not do this again
        context.commit("setAppInitialized");
    },
    updatePendingInvitations: (context) => {
        // check if we have pending groups, this can be done async 
        api.post("/user/self/membership/list", {
            count: true,
            limit: 0,
            filter: { 
                groupAccepted: { is: true },
                userAccepted: { is: false },
            },
        }).then(response => {
            context.commit("setPendingInvitations", response.data.response.count);
        }).catch(log);
    },
    getAccessLevels: (context, groupId) => {
        return new Promise((resolve, reject) => {
            if (source) {
                source.cancel("Request cancelled by subsequent request");
            }
            const options = {
                groupId: groupId,
            };
            source = axios.CancelToken.source();
            api.post("/user/self/access/get", options, {cancelToken: source.token})
                .then(response => {
                    context.commit("setAccess", response.data.response);
                    // context.commit("setActiveGroupAccess", response.data.response);
                    resolve(response);
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        // TODO figure out if we should reject here
                    } else {
                        context.commit("deactivateActiveGroup");
                        reject(error);
                    }
                });
        });
    },
    getLicenses: (context, groupId) => {
        // return here is necessary so route guard can hook into api.then()
        return api.post("/user/group/license/view", { groupId })
            .then(response => {
                context.commit("setLicense", response.data.response);
            })
            .catch(log);
    },
};