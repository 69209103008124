import { apiRequestFactory } from "@/api/util";


const apiSpec = {
    "/post/variant/add": {
        required: ["ownerGroupId", "postIds"],
    },

    "/post/variant/remove": {
        required: ["postIds", "ownerGroupId"],
    },

    "/post/variant/get": {
        required: ["postId", "ownerGroupId"],
        listResponse: "posts",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    add: (options) => apiRequest(options, "/post/variant/add"),
    remove: (options) => apiRequest(options, "/post/variant/remove"),
    get: (options) => apiRequest(options, "/post/variant/get"),
};
