import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const getDefaultState = () => {
    return {
        responses: [],
        respondents: {},
        respondentIds: {}, 
        isResponsesLoaded: false,

        currentPageId: null,

        questionnaireId: null,
        isQuestionnaireReady: false,
    };
};

const state = getDefaultState();

const respondModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default respondModule;
export { getDefaultState };
