import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const defaultStateObject = {
    activeTabs: {
        postBrowser: null,
        assetBrowser: null,
        groupBrowser: null,
        projectBrowser: null,
        dashboardBrowser: null,
        questionnaireBrowser: null,
    },
};

const getDefaultState = () => {
    return defaultStateObject;
};

const state = getDefaultState();

const browserModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default browserModule;
export { getDefaultState };