import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/customer/get": {
        required: ["customerId"],
    },
    "/customer/create": {
        required: ["name"],
    },
    "/customer/search": {
        required: [],
        listResponse: "customers",
    },
    "/customer/update": {
        required: ["customerId"],
    },
    "/customer/disable": {
        required: ["customerId"],
        booleanResponse: "disabled",
    },
    "/customer/restore": {
        required: ["customerId"],
        booleanResponse: "restored",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/customer/get", args),
    create: (options, args) => apiRequest(options, "/customer/create", args),
    search: (options, args) => apiRequest(options, "/customer/search", args),
    update: (options, args) => apiRequest(options, "/customer/update", args),
    disable: (options, args) => apiRequest(options, "/customer/disable", args),
    restore: (options, args) => apiRequest(options, "/customer/restore", args),
};
