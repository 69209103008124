import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const getDefaultState = () => {
    return {
        // This is used to store the details of the currently active group
        // when a superuser enters the super-admin part of the site. It is
        // later used to restore the active group when the superuser leaves
        // the super-admin part. This is done b/c the superuser can enter 
        // any group on the service while in the super-admin part.
        savedActiveGroup: {},
            customers: {
                data: null,
                fetchedTime: null,
            },
            groups: {
                data: null,
                fetchedTime: null,
            },
            contacts: {
                data: null,
                fetchedTime: null,
            },
            roles: {},
            access: {},
            licenses: {},
    };
};

const state = getDefaultState();

const adminModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default adminModule;
export { getDefaultState };
