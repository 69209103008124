import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    sessionId: localStorage.getItem("session-id") || "",
    status: "",
};

const authModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default authModule;