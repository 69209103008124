export default {
    "group.view": "read",
    "group.disable": "write",
    "group.destroy": "write",
    "profile.modify": "write",
    "license.modify": "licensing",
    "access.view": "read",
    "access.modify": "write",
    "members.view": "read",
    "members.add": "write",
    "members.remove": "write",
    "connections.view": "read",
    "connections.add": "write",
    "connections.remove": "write",
    "subgroups.view": "read",
    "subgroups.modify": "write",
    "post.view": "read",
    "post.modify": "write",
    "post.publish": "write",
    "post.destroy": "write",
    "discussion.view": "read",
    "discussion.disable": "write",
    "resource.view": "read",
    "resource.destroy": "write",
    "resource.transfer": "write",
    "resource.modify": "write",
    "resource.translate": "write",
    "questionnaire.lock": "write",
    "questionnaire.publish": "write",
    "responses.view": "responses",
    "responses.modify": "responses",
    "report.view": "read",
    "log.view": "read",
};