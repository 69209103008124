import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/response/list": {
        required: ["questionnaireId"],
        listResponse: "responses",
        attachToResponseItems: ["questionnaireId"],
    },
    "/response/update": {
        required: [
            "elementId",
            "languageCode", 
            "respondentId", 
            "responseNumber",
            "questionnaireId",
        ],
    },
    "/response/upload": {
        required: [
            "elementId",
            "elementType",
            "languageCode",
            "respondentId",
            "responseNumber",
            "questionnaireId",
        ],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    list: (options, args) => apiRequest(options, "/response/list", args),
    update: (options, args) => apiRequest(options, "/response/update", args),
    upload: (options, args) => apiRequest(options, "/response/upload", args),
};
