import { createApp, nextTick } from "vue";
import _ from "lodash";
import i18n from "@/i18n";
import icons from "@/icons"; // This import may appear unused, but is necessary for FA icons to show
import store from "@/store";
import config from "@/config";
import router from "@/router";

// ////////////////////////// The main App ///////////////////////////////////////////////
import App from "@/App";

const app = createApp(App);

// ////////////////////////// Vue ////////////////////////////////////////////////////////
app.config.globalProperties.productionTip = false;

Object.defineProperty(app.config.globalProperties, "$lodash", { value: _ });

// ////////////////////////// Dynamic meta tags in <head> //////////////////////////////// 
import { createMetaManager } from "vue-meta";
// check https://github.com/nuxt/vue-meta/releases/tag/v3.0.0-alpha.0
app.use(createMetaManager());

// ////////////////////////// Matomo analytics /////////////////////////////////////////// 
import VueMatomo from "vue-matomo";
if(
    config.matomo &&
    process.env.NODE_ENV !== "development"
) {
    app.use(VueMatomo, {
      host: "https://analytics.maptionnaire.com",
      router: router,
      siteId: config.matomo.siteId,
      domains: config.matomo.domains,
      // Use no-cookie mode
      disableCookies: true,
    });
}

// ////////////////////////// Sentry error tracking ////////////////////////////////////// 
import * as Sentry from "@sentry/vue";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
// import { Integrations } from "@sentry/tracing";

if(config.sentry) {
    Sentry.init({
        Vue: app,
        dsn: config.sentry.dsn,
        release: "maptionnaire2@1.0.5",
        ignoreErrors: [
            // These errors are likely caused by a non standard web crawler and compounded by
            // a bug in sentry which goes into a loop and triggers hundreds of these
            // See: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062 
            "Non-Error exception captured",
            "Non-Error promise rejection captured",
        ],
        logErrors: (process.env.NODE_ENV === "development" ? true : false),
        trackComponents: true,
        // Session tracking need a GDPR review before we can use it
        // autoSessionTracking: false,
        integrations: [
            new CaptureConsoleIntegration({
                levels: ["error"],
            }),
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: [
                    "localhost", 
                    "new.maptionnaire.com", 
                    "app.maptionnaire.com", 
                    /^\//,
                ],
            }),
        ],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}
// ////////////////////////// Vue media queries //////////////////////////////////////////
// NOTE: these should match the ones set in /styles/dimensions.scss
import { Vue3Mq } from "vue3-mq";

app.use(Vue3Mq, {
    breakpoints: {
        xs: 0,
        sm: 480,
        md: 900,
        lg: 1250,
        xl: 1450,
    },
});

// ////////////////////////// Oruga (to replace Buefy) ///////////////////////////////////
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from "@oruga-ui/theme-bulma";

app.use(Oruga, {
    ...bulmaConfig,
    iconPack: "fal",
});

app.mixin({
    computed: {
        $isDepreciatedBrowser: function () {
            // We use UA instead of feature detection, since this is not strictly a test 
            // of any individual functionality. Also these tests are for ancient versions 
            // of browsers that are unlikely to get updates that change their UA. Also if
            // someone want to surf the web with a spoofed UA then it is fine to show them
            // the depreciation notice.
            const ua = window.navigator.userAgent;

            // const isOpera = (
            //     /opera/i.test(ua) || // Opera < 13.0
            //     /opr\/|opios/i.test(ua) || // Opera > 13.0
            //     /coast/i.test(ua) || // Opera Coast
            //     /opt\/\d+(?:.?_?\d+)+/i.test(ua) // Opera touch
            // );
            const isIE = /msie|trident/i.test(ua);
            const isLegacyEdge = /\sedge\//i.test(ua); 

            return isIE || isLegacyEdge;
        },
    },
});

// This mixin can be used to check how many times method/computed has been called in vue components.
// app.config.devtools is only present in dev environment
if (app.config.devtools) window.__mpt_data__ = {};
app.mixin({
    methods: {
        $instrument: function (location) {
            if (!app?.config?.devtools) return;
            if (!window.__mpt_data__[location]) window.__mpt_data__[location] = 1;
            else window.__mpt_data__[location]++;
        },
        $getInstrumentationData: function() {
            // eslint-disable-next-line no-console
            if (app.config.devtools) console.log("DATA", window.__mpt_data__);
        },
    },
});

function setSelectedInputButton(el, binding, vnode) {
    // If the radio is checked, set the colour of the button to primary and
    // add is-selected class which buefy is using
    if (el?.getElementsByTagName("input")?.[0].checked) {
        el.classList.add("is-primary", "is-selected");
    } else {
        el.classList.remove("is-primary", "is-selected");
    }
}

// Directive for making Oruga o-radio like Buefy's o-radio-button.
// Use like: `<o-radio v-radio-button>...
app.directive("radio-button", {
    beforeMount: function (el, binding, vnode) {
        el.classList.add("control", "button");
        // Oruga adds an extra span with "control-label" around the label with padding,
        // but we don't want that for the buttons
        el.getElementsByClassName("control-label")?.[0].classList.remove("control-label");
        const radioInput = el.querySelectorAll("input[type=radio]")?.[0];
        radioInput.classList.add("is-hidden");
        radioInput.classList.remove("check");
        setSelectedInputButton(el, binding, vnode);
    },
    updated: function(el, binding, vnode) {
        setSelectedInputButton(el, binding, vnode);
    },
});

// Directive for making Oruga o-checkbox like Buefy's o-checkbox-button.
// Use like: `<o-radio v-checkbox-button>...
app.directive("checkbox-button", {
    beforeMount: function (el, binding, vnode) {
        el.classList.add("button");
        // Oruga adds an extra span with "control-label" around the label with padding,
        // but we don't want that for the buttons
        el.getElementsByClassName("control-label")?.[0].classList.remove("control-label");
        const checkboxInput = el.querySelectorAll("input[type=checkbox]")?.[0];
        checkboxInput.classList.add("is-hidden");
    },
    updated: function(el, binding, vnode) {
        nextTick(function () {
            // for unknown reasons button class is removed when a checkbox
            // becomes disabled, so we have to add it again
            if (!el.classList.contains("button")) {
                el.classList.add("button");
            }
        });
    },
});

// Icons?
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

// register FA components
app.component("fa-icon", FontAwesomeIcon);
app.component("fa-layers", FontAwesomeLayers);
app.component("fa-layers-text", FontAwesomeLayersText);

// Install instances to make the whole app aware
app.use(i18n);
app.use(store);
app.use(router);

// Mount app
router.isReady().then(() => app.mount("#app"));
