import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/group/license/current/get": {
        required: ["groupId"],
    },

};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/user/group/license/current/get", args),
};
