import { apiRequestFactory } from "@/api/util";
import { QuestionnairePropertyMap } from "@/../../server/src/shared/webdist/questionnaireProperties";

const apiSpec = {
    "/questionnaire/get": {
        required: ["questionnaireId"],
    },
    "/questionnaire/list": {
        required: ["ownerGroupId"],
        listResponse: "questionnaires",
    },
    "/questionnaire/clone": {
        required: ["cloneQuestionnaireId", "ownerGroupId", "name"],
    },
    "/questionnaire/create": {
        required: ["ownerGroupId"],
    },
    "/questionnaire/update": {
        required: ["questionnaireId"],
    },
    "/questionnaire/disable": {
        required: ["questionnaireId"],
        booleanResponse: "disabled",
    },
    "/questionnaire/restore": {
        required: ["questionnaireId"],
        booleanResponse: "restored",
    },
    "/questionnaire/destroy": {
        required: ["questionnaireId"],
        booleanResponse: "destroyed",
    },
    "/questionnaire/redact": {
        required: ["questionnaireId"],
        booleanResponse: "redacted",
    },
};

const typeSpec = {
    questionnaire: {
        properties: QuestionnairePropertyMap, 
    },
};

const apiRequest = apiRequestFactory(apiSpec, typeSpec, null, "questionnaire");

export default {
    get: (options, args) => apiRequest(options, "/questionnaire/get", args),
    list: (options, args) => apiRequest(options, "/questionnaire/list", args),
    clone: (options, args) => apiRequest(options, "/questionnaire/clone", args),
    create: (options, args) => apiRequest(options, "/questionnaire/create", args),
    update: (options, args) => apiRequest(options, "/questionnaire/update", args),
    disable: (options, args) => apiRequest(options, "/questionnaire/disable", args),
    restore: (options, args) => apiRequest(options, "/questionnaire/restore", args),
    destroy: (options, args) => apiRequest(options, "/questionnaire/destroy", args),
    redact: (options, args) => apiRequest(options, "/questionnaire/redact", args),
};
