import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/group/role/get": {
        required: ["ownerGroupId", "roleId"],
    },
    "/user/group/role/list": {
        required: ["ownerGroupId"],
        listResponse: "roles",
    },
    "/user/group/role/apply": {
        required: ["ownerGroupId", "roleId", "userId"],
    },
    "/user/group/role/create": {
        required: ["ownerGroupId"],
    },
    "/user/group/role/update": {
        required: ["ownerGroupId", "roleId"],
    },
    "/user/group/role/destroy": {
        required: ["ownerGroupId", "roleId"],
        booleanResponse: "destroyed",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/user/group/role/get", args),
    list: (options, args) => apiRequest(options, "/user/group/role/list", args),
    apply: (options, args) => apiRequest(options, "/user/group/role/apply", args),
    create: (options, args) => apiRequest(options, "/user/group/role/create", args),
    update: (options, args) => apiRequest(options, "/user/group/role/update", args),
    destroy: (options, args) => apiRequest(options, "/user/group/role/destroy", args),
};
