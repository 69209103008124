import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/response/batch/add": {
        required: ["questionnaireId", "respondentId", "responses"],
        listResponse: "responses",
    },
    "/response/batch/remove": {
        required: ["questionnaireId", "respondentId", "responses"],
        listResponse: "responses",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    add: (options, args) => apiRequest(options, "/response/batch/add", args),
    remove: (options, args) => apiRequest(options, "/response/batch/remove", args),
};
