import { apiRequestFactory } from "@/api/util";
import { MeasurementUnitPropertiesMap } from "@/../../server/src/shared/webdist/measurementUnit";

const apiSpec = {
    "/participation/project/currency/create": {
        required: ["projectId", "name", "budgetAmountMaximum"],
    },
    "/participation/project/currency/update": {
        required: ["projectId", "currencyId"],
    },
    "/participation/project/currency/destroy": {
        required: ["projectId", "currencyId"],
        booleanResponse: "destroyed",
    },
};

const typeSpec = {
    currency: {
        unitProperties: MeasurementUnitPropertiesMap, 
    },
};
const apiRequest = apiRequestFactory(apiSpec, typeSpec, null, "currency");

export default {
    create: (options) => apiRequest(options, "/participation/project/currency/create"),
    update: (options) => apiRequest(options, "/participation/project/currency/update"),
    destroy: (options) => apiRequest(options, "/participation/project/currency/destroy"),
};
