import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/group/get": {
        required: ["groupId"],
    },
    "/user/group/list": {
        required: [],
        listResponse: "groups",
    },
    "/user/group/view": {
        required: ["groupId"],
    },
    "/user/group/create": {
        required: ["name"],
    },
    "/user/group/update": {
        required: ["groupId"],
    },
    "/user/group/super/update": {
        required: ["groupId"],
    },
    "/user/group/disable": {
        required: ["groupId"],
        booleanResponse: "disabled",
    },
    "/user/group/restore": {
        required: ["groupId"],
        booleanResponse: "restored",
    },
    "/user/group/destroy": {
        required: ["groupId"],
        booleanResponse: "destroyed",
    },
    "/user/group/connect": {
        required: ["otherGroupId", "thisGroupId"],
        booleanResponse: "requestAccepted",
    },
    "/user/group/disconnect": {
        required: ["otherGroupId", "thisGroupId"],
        booleanResponse: "requestAccepted",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    get: (options, args) => apiRequest(options, "/user/group/get", args),
    list: (options, args) => apiRequest(options, "/user/group/list", args),
    view: (options, args) => apiRequest(options, "/user/group/view", args),
    create: (options, args) => apiRequest(options, "/user/group/create", args),
    update: (options, args) => apiRequest(options, "/user/group/update", args),
    disable: (options, args) => apiRequest(options, "/user/group/disable", args),
    restore: (options, args) => apiRequest(options, "/user/group/restore", args),
    destroy: (options, args) => apiRequest(options, "/user/group/destroy", args),
    connect: (options, args) => apiRequest(options, "/user/group/connect", args),
    disconnect: (options, args) => apiRequest(options, "/user/group/disconnect", args),    
};
