import {
    dom,
    library,
} from "@fortawesome/fontawesome-svg-core";
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
dom.watch();

// register FA components
// Vue.component("fa-icon", FontAwesomeIcon);
// Vue.component("fa-layers", FontAwesomeLayers);
// Vue.component("fa-layers-text", FontAwesomeLayersText);

import {
    faAlignCenter as falAlignCenter,
    faAlignLeft as falAlignLeft,
    faAlignRight as falAlignRight,
    faAngleDown as falAngleDown,
    faAngleUp as falAngleUp,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faArchive as falArchive,
    faArrowAltUp as falArrowAltUp,
    faArrowAltDown as falArrowAltDown,
    faArrowCircleLeft as falArrowCircleLeft,
    faArrowCircleRight as falArrowCircleRight,
    faArrowDown as falArrowDown,
    faArrowRight as falArrowRight,
    faArrowUp as falArrowUp,
    faArrowToBottom as falArrowToBottom,
    faArrowToRight as falArrowToRight,
    faArrowToTop as falArrowToTop,
    faBars as falBars,
    faBold as falBold,
    faBooks as falBooks,
    faBrowser as falBrowser,
    faBullhorn as falBullhorn,
    faCalendar as falCalendar,
    faCalendarAlt as falCalendarAlt,
    faCalendarCheck as falCalendarCheck,
    faCalendarTimes as falCalendarTimes,
    faChartArea as falChartArea,
    faChartBar as falChartBar,
    faChartScatter as falChartScatter,
    faCheck as falCheck,
    faCheckCircle as falCheckCircle,
    faCheckSquare as falCheckSquare,
    faChevronDoubleLeft as falChevronDoubleLeft,
    faChevronDoubleRight as falChevronDoubleRight,
    faChevronDown as falChevronDown,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faChevronUp as falChevronUp,
    faCircleNotch as falCircleNotch,
    faClipboardListCheck as falClipboardListCheck,
    faClock as falClock,
    faCode as falCode,
    faCog as falCog,
    faCommentAltDots as falCommentAltDots,
    faCommentsAltDollar as falCommentsAltDollar,
    faCommentAltLines as falCommentAltLines,
    faCopy as falCopy,
    faDrawPolygon as falDrawPolygon,
    faEllipsisH as falEllipsisH,
    faEnvelope as falEnvelope,
    faExclamationCircle as falExclamationCircle,
    faExclamationTriangle as falExclamationTriangle,
    faExpandWide as falExpandWide,
    faExternalLink as falExternalLink,
    faExternalLinkAlt as falExternalLinkAlt,
    faExternalLinkSquare as falExternalLinkSquare,
    faEye as falEye,
    faFile as falFile,
    faFileAlt as falFileAlt,
    faFileArchive as falFileArchive,
    faFileDownload as falFileDownload,
    faFilter as falFilter,
    faFlask as falFlask,
    faGlobe as falGlobe,
    faGlobeAfrica as falGlobeAfrica,
    faGripHorizontal as falGripHorizontal,
    faH1 as falH1,
    faH2 as falH2,
    faH3 as falH3,
    faH4 as falH4,
    faHandPointer as falHandPointer,
    faHeading as falHeading,
    faHorizontalRule as falHorizontalRule,
    faImage as falImage,
    faInfoCircle as falInfoCircle,
    faLayerGroup as falLayerGroup,
    faLightbulbOn as falLightbulbOn,
    faListUl as falListUl,
    faListOl as falListOl,
    faLocationArrow as falLocationArrow,
    faLock as falLock,
    faLockOpen as falLockOpen,
    faMap as falMap,
    faMapMarkedAlt as falMapMarkedAlt,
    faMapMarker as falMapMarker,
    faMapMarkerAlt as falMapMarkerAlt,
    faMapMarkerMinus as falMapMarkerMinus,
    faMapMarkerQuestion as falMapMarkerQuestion,
    faPager as falPager,
    faMinus as falMinus,
    faMusic as falMusic,
    faMoneyCheckAlt as falMoneyCheckAlt,
    faObjectsColumn as falObjectsColumn,
    faParagraph as falParagraph,
    faPen as falPen,
    faPencilRuler as falPencilRuler,
    faPenFancy as falPenFancy,
    faPlus as falPlus,
    faPlusCircle as falPlusCircle,
    faPollPeople as falPollPeople,
    faQuestionCircle as falQuestionCircle,
    faQuestionSquare as falQuestionSquare,
    faScroll as falScroll,
    faSearch as falSearch,
    faShare as falShare,
    faShareAlt as falShareAlt,
    faShieldCheck as falShieldCheck,
    faSignature as falSignature,
    faSitemap as falSitemap,
    faSlidersH as falSlidersH,
    faSortAlphaDown as falSortAlphaDown,
    faSortAlphaUp as falSortAlphaUp,
    faSortAmountDown as falSortAmountDown,
    faSortAmountUp as falSortAmountUp,
    faSortNumericDown as falSortNumericDown,
    faSortNumericUp as falSortNumericUp,
    faSpinnerThird as falSpinnerThird,
    faTachometerAlt as falTachometerAlt,
    faTag as falTag,
    faTasks as falTasks,
    faTh as falTh,
    faThList as falThList,
    faTimes as falTimes,
    faTrash as falTrash,
    faTrashAlt as falTrashAlt,
    faUndo as falUndo,
    faUser as falUser,
    faUserSecret as falUserSecret,
    faVideo as falVideo,
    faVolumeUp as falVolumeUp,
    faWifi1 as falWifi1,
    faUserTag as falUserTag,
} from "@fortawesome/pro-light-svg-icons";
import {
    faAlignLeft as farAlignLeft,
    faAngleDown as farAngleDown,
    faAngleUp as farAngleUp,
    faBold as farBold,
    faCircle as farCircle,
    faChartBar as farChartBar,
    faCheck as farCheck,
    faCheckSquare as farCheckSquare,
    faChevronDown as farChevronDown,
    faChevronUp as farChevronUp,
    faCog as farCog,
    faCommentAltDots as farCommentAltDots,
    faDollarSign as farDollarSign,
    faDoNotEnter as farDoNotEnter,
    faDotCircle as farDotCircle,
    faExclamationTriangle as farExclamationTriangle,
    faExternalLink as farExternalLink,
    faEye as farEye,
    faEyeSlash as farEyeSlash,
    faFile as farFile,
    faFileCertificate as farFileCertificate,
    faDownload as farDownload,
    faFilter as farFilter,
    faHighlighter as farHighlighter,
    faLanguage as farLanguage,
    faLightbulbOn as farLightbulbOn,
    faListOl as farListOl,
    faMicroscope as farMicroscope,
    faNotEqual as farNotEqual,
    faPen as farPen,
    faPlusCircle as farPlusCircle,
    faQuestionCircle as farQuestionCircle,
    faProjectDiagram as farProjectDiagram,
    faScroll as farScroll,
    faSearch as farSearch,
    faSkull as farSkull,
    faSpinnerThird as farSpinnerThird,
    faSquare as farSquare,
    faStar as farStar,
    faUndo as farUndo,
    faUser as farUser,
    faUserSlash as farUserSlash,
    faText as farText,
    faTimes as farTimes,
    faUserTag as farUserTag,
} from "@fortawesome/pro-regular-svg-icons";
import {
    faArrowAltUp as fasArrowAltUp,
    faArrowAltDown as fasArrowAltDown,
    faArrowRight as fasArrowRight,
    faArrowLeft as fasArrowLeft,
    faBold as fasBold,
    faCalendarCheck as fasCalendarCheck,
    faCheckCircle as fasCheckCircle,
    faChevronDown as fasChevronDown,
    faChevronCircleDown as fasChevronCircleDown,
    faChevronCircleUp as fasChevronCircleUp,
    faChevronUp as fasChevronUp,
    faEllipsisH as fasEllipsisH,
    faGripVertical as fasGripVertical,
    faHandPointer as fasHandPointer,
    faLocationArrow as fasLocationArrow,
    faMap as fasMap,
    faMusic as fasMusic,
    faReply as fasReply,
    faTimesCircle as fasTimesCircle,
    faUser as fasUser,
    faWifi1 as fasWifi1,
    faUserTag as fasUserTag,
} from "@fortawesome/pro-solid-svg-icons";
import {
    faCreativeCommonsZero as fabCreativeCommonsZero,
    faFacebook as fabFacebook,
    faGoogle as fabGoogle,
    faMicrosoft as fabMicrosoft,
    faInstagram as fabInstagram,
    faLinkedin as fabLinkedIn,
    faPinterest as fabPinterest,
    faReddit as fabReddit,
    faTwitter as fabTwitter,
    faVimeo as fabVimeo,
    faVimeoSquare as fabVimeoSquare,
    faYoutube as fabYoutube,
    faYoutubeSquare as fabYoutubeSquare,
    faXTwitter as faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
    faMapbox as fabMapbox,
    faArea as falArea,
    faBing as fabBing,
} from "./custom";
library.add(
    fabBing,
    fabCreativeCommonsZero,
    fabFacebook,
    fabGoogle,
    fabMicrosoft,
    fabInstagram,
    fabLinkedIn,
    fabMapbox,
    fabPinterest,
    fabReddit,
    fabTwitter,
    fabVimeo,
    fabVimeoSquare,
    fabYoutube,
    fabYoutubeSquare,
    falAlignCenter,
    falAlignLeft,
    falAlignRight,
    falAngleDown,
    falAngleUp,
    falAngleLeft,
    falAngleRight,
    falArchive,
    falArea,
    falArrowAltUp,
    falArrowAltDown,
    falArrowCircleLeft,
    falArrowCircleRight,
    falArrowDown,
    falArrowRight,
    falArrowToBottom,
    falArrowToRight,
    falArrowToTop,
    falArrowUp,
    falBars,
    falBold,
    falBooks,
    falBrowser,
    falBullhorn,
    falCalendar,
    falCalendarAlt,
    falCalendarCheck,
    falCalendarTimes,
    falChartArea,
    falChartBar,
    falChartScatter,
    falCheck,
    falCheckCircle,
    falCheckSquare,
    falChevronDoubleLeft,
    falChevronDoubleRight,
    falChevronDown,
    falChevronLeft,
    falChevronRight,
    falChevronUp,
    falCircleNotch,
    falClipboardListCheck,
    falClock,
    falCode,
    falCog,
    falCommentAltDots,
    falCommentsAltDollar,
    falCommentAltLines,
    falCopy,
    falDrawPolygon,
    falEllipsisH,
    falEnvelope,
    falExclamationCircle,
    falExclamationTriangle,
    falExpandWide,
    falExternalLink,
    falExternalLinkAlt,
    falExternalLinkSquare,
    falEye,
    falFile,
    falFileAlt,
    falFileArchive,
    falFileDownload,
    falFilter,
    falFlask,
    falGlobe,
    falGlobeAfrica,
    falGripHorizontal,
    falH1,
    falH2,
    falH3,
    falH4,
    falHandPointer,
    falHeading,
    falHorizontalRule,
    falImage,
    falInfoCircle,
    falLayerGroup,
    falLightbulbOn,
    falListUl,
    falListOl,
    falLocationArrow,
    falLock,
    falLockOpen,
    falMap,
    falMapMarkedAlt,
    falMapMarker,
    falMapMarkerAlt,
    falMapMarkerMinus,
    falMapMarkerQuestion,
    falPager,
    falMinus,
    falMusic,
    falMoneyCheckAlt,
    falObjectsColumn,
    falParagraph,
    falPen,
    falPencilRuler,
    falPenFancy,
    falPlusCircle,
    falPlus,
    falPollPeople,
    falQuestionCircle,
    falQuestionSquare,
    falScroll,
    falSearch,
    falShare,
    falShareAlt,
    falShieldCheck,
    falSignature,
    falSitemap,
    falSlidersH,
    falSortAlphaDown,
    falSortAlphaUp,
    falSortAmountDown,
    falSortAmountUp,
    falSortNumericDown,
    falSortNumericUp,
    falSpinnerThird,
    falTachometerAlt,
    falTag,
    falTasks,
    falTh,
    falThList,
    falTimes,
    falTrash,
    falTrashAlt,
    falUndo,
    falUser,
    falUserTag,
    falUserSecret,
    falVideo,
    falVolumeUp,
    falWifi1,
    farAlignLeft,
    farAngleDown,
    farAngleUp,
    farBold,
    farCircle,
    farChartBar,
    farCheck,
    farCheckSquare,
    farCog,
    farCommentAltDots,
    farChevronDown,
    farChevronUp,
    farDollarSign,
    farDotCircle,
    farDoNotEnter,
    farExclamationTriangle,
    farExternalLink,
    farEye,
    farEyeSlash,
    farFile,
    farFileCertificate,
    farDownload,
    farFilter,
    farHighlighter,
    farLanguage,
    farLightbulbOn,
    farListOl,
    farMicroscope,
    farNotEqual,
    farPen,
    farPlusCircle,
    farQuestionCircle,
    farProjectDiagram,
    farScroll,
    farSearch,
    farSkull,
    farSpinnerThird,
    farSquare,
    farStar,
    farText,
    farTimes,
    farUndo,
    farUser,
    farUserSlash,
    farUserTag,
    fasArrowAltUp,
    fasArrowAltDown,
    fasArrowRight,
    fasArrowLeft,
    fasBold,
    fasCalendarCheck,
    fasCheckCircle,
    fasChevronCircleDown,
    fasChevronCircleUp,
    fasChevronDown,
    fasChevronUp,
    fasEllipsisH,
    fasGripVertical,
    fasHandPointer,
    fasLocationArrow,
    fasMap,
    fasMusic,
    fasTimesCircle,
    fasReply,
    fasUser,
    fasUserTag,
    fasWifi1,
    faXTwitter
);
export default {
    FontAwesomeIcon,
};
