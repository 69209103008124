import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/questionnaire/respondent/export/csv": {
        required: ["questionnaireId"],
        isDataResponse: true,
    },
    "/questionnaire/respondent/export/pdf": {
        required: ["languageCode", "respondentId", "questionnaireId"],
        isDataResponse: true,
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    csv: (options, args) => apiRequest(options, "/questionnaire/respondent/export/csv", args),
    pdf: (options, args) => apiRequest(options, "/questionnaire/respondent/export/pdf", args),
};
