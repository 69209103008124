import { apiRequestFactory } from "@/api/util";
const apiSpec = {
    "/respondent/self/get": {
        required: ["questionnaireId", "respondentId"],
    },
    "/respondent/self/identify": {
        required: ["questionnaireId"],
    },
    "/respondent/self/authenticate": {
        required: ["questionnaireId", "respondentId"],
    },
    "/respondent/self/submit": {
        required: ["questionnaireId", "respondentId"],
        booleanResponse: "submitted",
    },
    "/respondent/self/update": {
        required: ["questionnaireId", "respondentId"],
        booleanResponse: ["disabledByRespondent", "participatoryConsent", "publicationConsent"],
    },
    "/respondent/self/list": {
        required: null,
    },
    "/respondent/self/redact": {
        required: ["questionnaireId", "respondentId"],
        booleanResponse: "redacted",
    },
};
const apiRequest = apiRequestFactory(apiSpec);
export default {
    get: (options, args) => apiRequest(options, "/respondent/self/get", args),
    identify: (options, args) => apiRequest(options, "/respondent/self/identify", args),
    authenticate: (options, args) => apiRequest(options, "/respondent/self/authenticate", args),
    submit: (options, args) => apiRequest(options, "/respondent/self/submit", args),
    update: (options, args) => apiRequest(options, "/respondent/self/update", args),
    list: (options, args) => apiRequest(options, "/respondent/self/list", args),
    redact: (options, args) => apiRequest(options, "/respondent/self/redact", args),
};