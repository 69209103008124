import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import config from "@/config";

import routeGuards from "./routeGuards";
import { defineAsyncComponent } from "vue";

// Lazy loader for components. See https://router.vuejs.org/guide/advanced/lazy-loading.html
export function lazyImport (component) {
    return defineAsyncComponent(() => import(
        /* webpackChunkName: "view-[request]" */
        `@/components/${component}`
    ));
}

// Lazy loader for route components. See https://router.vuejs.org/guide/advanced/lazy-loading.html
export function lazyImportRoute (component) {
    return () => import(
        /* webpackChunkName: "view-[request]" */
        `@/components/${component}`
    );
}

// TODO: implement systematic pre-fetching for respondent view components
// https://medium.com/@mrodal/how-to-make-lazy-loading-actually-work-in-vue-cli-3-7f3f88cfb102
export function lazyImportPrefetch (component) {
    return defineAsyncComponent(() => import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "view-[request]" */
        `@/components/${component}`
    ));
}

const Public = lazyImportRoute("Public");
const Respond = lazyImportRoute("public-elements/Respond");
const IdeaPage = lazyImportRoute("public-elements/IdeaPage");
const PostPage = lazyImportRoute("public-elements/PostPage");
const FrontPage = lazyImportRoute("public-elements/FrontPage");
const TokenHandler = lazyImportRoute("public-elements/TokenHandler");
const GroupFrontPage = lazyImportRoute("public-elements/GroupFrontPage");

const Auth = lazyImportRoute("Auth");
const Login = lazyImportRoute("auth-elements/Login");
const OtpLogin = lazyImportRoute("auth-elements/OtpLogin");
const ResetAsk = lazyImportRoute("auth-elements/ResetAsk");
const Register = lazyImportRoute("auth-elements/Register");
const SetupConfirm = lazyImportRoute("auth-elements/SetupConfirm");
const SuomiFiRedirect = lazyImportRoute("auth-elements/SuomiFiRedirect");
const OpenIdRedirect = lazyImportRoute("auth-elements/OpenIdRedirect");
const SuomiFiLogout = lazyImportRoute("auth-elements/SuomiFiLogout");
const ResetConfirm = lazyImportRoute("auth-elements/ResetConfirm");
const EmailConfirm = lazyImportRoute("auth-elements/EmailConfirm");
const EmailChangeConfirm = lazyImportRoute("auth-elements/EmailChangeConfirm");

const Admin = lazyImportRoute("Admin");
const AdminGroups = lazyImportRoute("admin-elements/Groups");
const AdminContacts = lazyImportRoute("admin-elements/Contacts");
const AdminCustomers = lazyImportRoute("admin-elements/Customers");
const AdminStats = lazyImportRoute("admin-elements/Stats");
const AdminBusiness = lazyImportRoute("admin-elements/BusinessTab");
const AdminUsers = lazyImportRoute("admin-elements/UsersTab");

const Browser = lazyImportRoute("Browser");
const PostBrowser = lazyImportRoute("browser-elements/PostBrowser");
const ProjectBrowser = lazyImportRoute("browser-elements/ProjectBrowser");
const AssetBrowser = lazyImportRoute("browser-elements/AssetBrowser");
const IdeaBrowser = lazyImportRoute("browser-elements/IdeaBrowser");
const ProposalBrowser = lazyImportRoute("browser-elements/ProposalBrowser");
const DashboardBrowser = lazyImportRoute("browser-elements/DashboardBrowser");
const QuestionnaireBrowser = lazyImportRoute("browser-elements/QuestionnaireBrowser");

const Groups = lazyImportRoute("Groups");
const GroupBrowser = lazyImportRoute("group-elements/GroupBrowser");

const User = lazyImportRoute("User");
const UserData = lazyImportRoute("user-elements/UserData");
const UserDisabled = lazyImportRoute("user-elements/UserDisabled");
const UserBilling = lazyImportRoute("user-elements/UserBilling");
const UserSettings = lazyImportRoute("user-elements/UserSettings");
const UserSubscription = lazyImportRoute("user-elements/UserSubscription");
const UserOrganization = lazyImportRoute("user-elements/UserOrganization");

const PostEditor = lazyImportRoute("PostEditor");
const PostEdit = lazyImportRoute("post-editor-elements/PostEdit");
const PostSettings = lazyImportRoute("post-editor-elements/PostSettings");
const PostPreview = lazyImportRoute("post-editor-elements/PostPreview");
const PostGroupSettings = lazyImportRoute("post-editor-elements/PostGroupSettings");
const PostPublish = lazyImportRoute("post-editor-elements/PostPublish");
const PostTranslate = lazyImportRoute("post-editor-elements/PostTranslate");

const AssetSettings = lazyImportRoute("asset-elements/AssetSettings");

const QuestionnaireEditor = lazyImportRoute("QuestionnaireEditor");
const QuestionnaireEdit = lazyImportRoute("questionnaire-editor-elements/QuestionnaireEdit");
const QuestionnairePreview = lazyImportRoute("questionnaire-editor-elements/QuestionnairePreview");
const QuestionnairePublish = lazyImportRoute("questionnaire-editor-elements/QuestionnairePublish");
const QuestionnaireResults = lazyImportRoute("questionnaire-editor-elements/QuestionnaireResults");
const QuestionnaireAnalyse = lazyImportRoute("questionnaire-editor-elements/QuestionnaireAnalyse");
const QuestionnaireTranslate = lazyImportRoute("questionnaire-editor-elements/QuestionnaireTranslate");

const ProjectEditor = lazyImportRoute("ProjectEditor");
const ProjectSettings = lazyImportRoute("project-editor-elements/ProjectSettings");
const ProjectRespondents = lazyImportRoute("project-editor-elements/ProjectRespondents");
const ProposalPage = lazyImportRoute("public-elements/ProposalPage");

// const Setup = lazyImportRoute("Setup");
// const SetupGroups = lazyImportRoute("setup-elements/SetupGroups");
// const SetupProfile = lazyImportRoute("setup-elements/SetupProfile");

const StaticRoute = lazyImportRoute("public-elements/StaticRoute");

// const ErrorPage = lazyImportRoute("ErrorPage");
const PageNotFound = lazyImportRoute("PageNotFound");

// Vue.use(VueRouter);

// //////////////////////// Router ///////////////////////////////////////////////////////
//
// meta: {
//     isPublic: indicates a public router branch. This is used in app for deciding if to
//         apply custom tenant styles, show internal navigation options, etc.
//     isPreview: indicates a preview router branch, i.e., routes where even though you
//         are logged in, components should behave like it is a public route
//     requiresAuth: indicates a route branch where the user must be logged in. Used in
//         app to prevent navigation and redirect to login
//     requireUnauth: indicates a route branch where the user must be logged out, used in
//         app to log current user out before proceeding with navigation.
//     requiresGroup: indicates a route branch that requires that the user has at least
//         one group. Used in app to prevent navigation if the config option
//         config.accessConfig.allowGroupCreation is `true`
// }

const routeDefinition = {
    history: createWebHistory(),
    routes: [{
        path: "/",
        component: Public,
        meta: {
            isPublic: true,
        },
        children: [{
            path: "",
            name: "FrontPage",
            component: FrontPage,
        }, {
            path: "/p/:postId",
            name: "PostPage",
            component: PostPage,
        }, {
            path: "/i/:projectId/:ideaId",
            name: "IdeaPage",
            component: IdeaPage,
        }, {
            path: "/o/:projectId/:proposalId",
            name: "ProposalPage",
            component: ProposalPage,
        }, {
            path: "/q/:questionnaireId",
            name: "Respond",
            component: Respond,
        }, {
            path: "/t/:token",
            name: "TokenHandler",
            component: TokenHandler,
        }, {
            path: "/g/:groupId",
            name: "GroupFrontPage",
            component: GroupFrontPage,
            beforeEnter: groupHasLicense("feature:group:frontpage"),
        }],
    }, {
        path: "/admin",
        component: Admin,
        beforeEnter: isSuper("any"),
        meta: {
            isSuper: true,
            requiresAuth: true,
            analyticsIgnore: true,
        },
        children: [{
            path: "",
            name: "Admin",
            redirect: "groups",
        }, {
            path: "groups",
            name: "AdminGroups",
            component: AdminGroups,
            beforeEnter: isSuper("licensing"),
        }, {
            path: "customers",
            name: "AdminCustomers",
            component: AdminCustomers,
            beforeEnter: isSuper("licensing"),
        }, {
            path: "contacts",
            name: "AdminContacts",
            component: AdminContacts,
            beforeEnter: isSuper("licensing"),
        }, {
            path: "stats",
            name: "AdminStats",
            component: AdminStats,
            beforeEnter: isSuper("licensing"),
        }, {
            path: "business",
            name: "AdminBusiness",
            component: AdminBusiness,
            beforeEnter: isSuper("licensing"),
        }, {
            path: "users",
            name: "AdminUsers",
            component: AdminUsers,
            beforeEnter: isSuper("licensing"),
        }],
    }, {
        path: "/auth",
        component: Auth,
        meta: {
            isPublic: true,
        },
        children: [{
            path: "login",
            name: "Login",
            component: Login,
            meta: {
                requiresUnauth: true,
            },
        }, {
            path: "otp",
            name: "OtpLogin",
            component: OtpLogin,
        }, {
            path: "register",
            name: "Register",
            component: Register,
            meta: {
                requiresUnauth: true,
            },
        }, {
            path: "email/confirm/:tokenId",
            name: "EmailConfirm",
            component: EmailConfirm,
            meta: {
                analyticsIgnore: true,
            },
        }, {
            path: "email/change/confirm/:tokenId",
            name: "EmailChangeConfirm",
            component: EmailChangeConfirm,
            meta: {
                analyticsIgnore: true,
            },
        }, {
            path: "reset",
            name: "ResetAsk",
            component: ResetAsk,
            meta: {
                requiresUnauth: true,
            },
        }, {
            path: "reset/:tokenId",
            name: "ResetConfirm",
            component: ResetConfirm,
            meta: {
                requiresUnauth: true,
                analyticsIgnore: true,
            },
        }, {
            path: "setup/:tokenId",
            name: "SetupConfirm",
            component: SetupConfirm,
            meta: {
                requiresUnauth: true,
                analyticsIgnore: true,
            },
          }, {
            path: "suomifi-redirect",
            name: "SuomiFiRedirect",
            component: SuomiFiRedirect,
            meta: {
                analyticsIgnore: true,
            },
          }, {
            path: "openid-redirect",
            name: "OpenIdRedirect",
            component: OpenIdRedirect,
            meta: {
                analyticsIgnore: true,
            },
          }, {
            path: "suomifi-logout",
            name: "SuomiFiLogout",
            component: SuomiFiLogout,
            meta: {
                analyticsIgnore: true,
            },
          },
         ],
    }, {
        path: "/browse",
        component: Browser,
        meta: {
            requiresAuth: true,
            requiresGroup: true,
        },
        children: [{
            path: "",
            redirect: "questionnaires",
        }, {
            path: "questionnaires",
            name: "QuestionnaireBrowser",
            component: QuestionnaireBrowser,
        }, {
            path: "posts",
            name: "PostBrowser",
            component: PostBrowser,
            beforeEnter: hasLicense("module:websites"),
        }, {
            path: "projects",
            name: "ProjectBrowser",
            component: ProjectBrowser,
            beforeEnter: hasLicense("module:websites"),
        }, {
            path: "assets",
            name: "AssetBrowser",
            component: AssetBrowser,
        }, {
            path: "dashboard",
            name: "DashboardBrowser",
            component: DashboardBrowser,
        }, {
            path: "bulk-edit",
            name: "PostBulkEdit",
            component: PostGroupSettings,
        },
        {
            path: "/asset/edit/:assetId",
            name: "AssetSettings",
            component: AssetSettings,
        
        },
    ],
    }, {
        path: "/groups",
        component: Groups,
        meta: {
            requiresAuth: true,
            requiresGroup: true,
        },
        children: [{
            path: "",
            name: "Groups",
            redirect: "browse",
        }, {
            path: "browse",
            name: "GroupBrowser",
            component: GroupBrowser,
        }],
    }, {
        path: "/user",
        component: User,
        meta: {
            isPublic: true,
            requiresAuth: true,
        },
        children: [{
            path: "",
            redirect: "profile",
        }, {
            path: "settings",
            name: "Settings",
            component: UserSettings,
        }, {
            path: "data",
            name: "Data",
            component: UserData,
        }, {
            path: "subscription",
            name: "Subscription",
            component: UserSubscription,
        }, {
            path: "billing",
            name: "Billing",
            component: UserBilling,
        }, {
            path: "disabled",
            name: "UserDisabled",
            component: UserDisabled,
        }],
    }, {
        path: "/post",
        meta: {
            requiresAuth: true,
            requiresGroup: true,
        },
        component: PostEditor,
        beforeEnter: hasLicense("module:websites"),
        children: [{
            path: "",
            redirect: "/browse/posts",
        }, {
            path: "edit/:postId",
            name: "PostEdit",
            component: PostEdit,
            meta: { forceGroupIdBy: "postId" },
        }, {
            path: "settings/:postId",
            name: "PostSettings",
            component: PostSettings,
            meta: { forceGroupIdBy: "postId" },
        }, {
            path: "translate/:postId",
            name: "PostTranslate",
            component: PostTranslate,
            meta: { forceGroupIdBy: "postId" },
        }, {
            path: "preview/:postId",
            name: "PostPreview",
            component: PostPreview,
            meta: { forceGroupIdBy: "postId", isPreview: true },
        }, {
            path: "publish/:postId",
            name: "PostPublish",
            component: PostPublish,
            meta: { forceGroupIdBy: "postId" },
        }, 
    ],
    }, {
        path: "/questionnaire",
        meta: {
            isQuestionnaireEditor: true,
            requiresAuth: true,
            requiresGroup: true,
        },
        beforeEnter: routeGuards.resetEditorStateGuard,
        component: QuestionnaireEditor,
        children: [{
            path: "",
            redirect: "/browse/questionnaires",
        }, {
            path: "edit/:questionnaireId",
            name: "QuestionnaireEdit",
            component: QuestionnaireEdit,
            meta: { forceGroupIdBy: "questionnaireId" },
        }, {
            path: "translate/:questionnaireId",
            name: "QuestionnaireTranslate",
            component: QuestionnaireTranslate,
            meta: { forceGroupIdBy: "questionnaireId" },
        }, {
            path: "preview/:questionnaireId",
            name: "QuestionnairePreview",
            component: QuestionnairePreview,
            meta: { forceGroupIdBy: "questionnaireId", isPreview: true },
        }, {
            path: "publish/:questionnaireId",
            name: "QuestionnairePublish",
            component: QuestionnairePublish,
            meta: { forceGroupIdBy: "questionnaireId" },
        }, {
            path: "analyse/:questionnaireId/:tab?",
            name: "QuestionnaireAnalyse",
            component: QuestionnaireAnalyse,
            meta: { forceGroupIdBy: "questionnaireId" },
        }, {
            path: "results/:questionnaireId",
            name: "QuestionnaireResults",
            component: QuestionnaireResults,
            meta: { forceGroupIdBy: "questionnaireId" },
        }],
    },
    {
        path: "/project",
        meta: {
            isProjectEditor: true,
            requiresAuth: true,
            requiresGroup: true,
        },
        component: ProjectEditor,
        children: [{
            path: "",
            redirect: "/browse/projects",
        }, {
            path: "settings/:projectId",
            name: "ProjectSettings",
            component: ProjectSettings,
            meta: { forceGroupIdBy: "projectId" },
        }, {
            path: "ideas/:projectId",
            name: "IdeaBrowser",
            component: IdeaBrowser,
            meta: { forceGroupIdBy: "projectId" },
        }, {
            path: "ideas/:projectId/filter-by-respondent-id/:respondentId",
            name: "IdeaBrowserFilterByRespondentId",
            component: IdeaBrowser,
            meta: { forceGroupIdBy: "projectId" },
        }, {
            path: "ideas/:projectId/filter-by-proposal-id/:proposalId",
            name: "IdeaBrowserFilterByProposalId",
            component: IdeaBrowser,
            meta: { forceGroupIdBy: "projectId" },
        }, {
            path: "proposals/:projectId",
            name: "ProposalBrowser",
            component: ProposalBrowser,
            meta: { forceGroupIdBy: "projectId" },
        }, {
            path: "proposals/:projectId/filter-by-id/:proposalId",
            name: "ProposalBrowserFilterById",
            component: ProposalBrowser,
            meta: { forceGroupIdBy: "projectId" },
        }, {
            path: "respondents/:projectId/:questionnaireId",
            name: "ProjectRespondents",
            component: ProjectRespondents,
            meta: { forceGroupIdBy: "projectId" },
        }],
    },
    /* {
        path: "/setup",
        component: Setup,
        meta: { requiresAuth: true },
        children: [{
            path: "groups",
            name: "SetupGroups",
            component: SetupGroups,
        }, {
            path: "profile",
            name: "SetupProfile",
            component: SetupProfile,
        }],
    },*/ /* {
        path: "/error/:errorCode",
        name: "ErrorPage",
        component: ErrorPage,
    },*/ {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: PageNotFound,
    }],
};

// This is currently used for adding the /osbu route for V
if (config?.additionalStaticRoutes?.length) {
    config.additionalStaticRoutes.forEach(r => {
        // Check that definition is OK
        if (
            !r?.path?.startsWith?.("/") ||
            !["post"].includes?.(r?.type)
        ) return;
        // Unshift is needed b/c of the way vue router handles route precedence
        routeDefinition.routes.unshift({
            path: r.path,
            component: StaticRoute,
            props: {
                type: r.type,
                resourceId: r.resourceId,
            },
            meta: {
                isPublic: true,
            },
        });
    });
}


export const router = createRouter(routeDefinition);


// ////////////////////// Error handling /////////////////////////////////////////////////
//
// Chunk cache busting + lazy loading means that right after a deploy, users who have not
// reloaded since the deploy, will get errors since the chunks they are trying to load
// have disappeared.
//
// See:
// https://stackoverflow.com/questions/59385650/vuejs-browser-caching-and-loading-chunk-failed-issue
// https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
//
// This has the potential for an infinite loop :-(. TODO: figure out how to SENTRY if this
// is looping
//
router.onError(error => {
    const message = "The app has been updated, please reload the page to get the latest changes.";
    if (
        navigator.onLine && (
            error.name === "ChunkLoadError" ||
            /loading chunk \d* failed./i.test(error.message)
        )
    ) {
        /* eslint no-alert: "off" */
        if (window.confirm(message)) {
            window.location.reload();
        }
    }
});

// ////////////////////// Navigation guards //////////////////////////////////////////////
//
// NOTE: The order in which these are registered matters!!
//
// Check that app has inited
router.beforeEach(routeGuards.initGuard);
// Check that idb has inited
router.beforeEach(routeGuards.idbGuard);
// Check that user is not disabled
router.beforeEach(routeGuards.disabledGuard);
// Check if we need to force active group
router.beforeEach(routeGuards.forceActiveGroupGuard);
// Check and apply authentication guards
router.beforeEach(routeGuards.authGuard);
// Check and apply other requirements (currently only requiresGroup)
router.beforeEach(routeGuards.groupGuard);
// Check and apply user language preference
router.beforeEach(routeGuards.languageGuard);
// In line guards for checking license access
function hasLicense (license) {
    return (to, from, next) => {
        if (store.getters.has(license)) {
            next();
        } else {
            next(false);
        }
    };
}
function groupHasLicense (license) {
    return (to, from, next) => {
        // Try if license info is already loaded
        if (store.getters.groupHas(license, to.params.groupId)) {
            next();
        }
        // Load license (will take care of saving license in store)
        store.dispatch("getLicenses", to.params.groupId)
            .then(() => {
                // Try license again
                if (store.getters.groupHas(license, to.params.groupId)) {
                    next();
                } else {
                    next(false);
                }
            })
            .catch(error => {
                next(false);
            });
    };
}
// In line guard for checking superuser status
function isSuper (type) {
    return (to, from, next) => {
        if (store.getters.isSuper(type)) {
            next();
        } else {
            next({ path: "/"});
        }
    };
}



export default router;