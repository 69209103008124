// TODO: move this file into @/util/

import _ from "lodash";

import log from "@/log";
import store from "@/store";

import { AssetType } from "@/../../server/src/shared/webdist/assetType";
import { PostElementType } from "@/../../server/src/shared/webdist/postElementType";

import { 
    PostPropertyList, 
    PostPropertyMap, 
} from "@/../../server/src/shared/webdist/postProperties";

export { Properties } from "./properties";

/** *
 * Utilities for constructing state objects
 ***/

export function sortByIndex (a, b) {
    return (a.index || 0) - (b.index || 0);
}

export function sortByPageNumber (a, b) {
    return (a.pageNumber || 0) - (b.pageNumber || 0);
}


export function sortByParentPageNumberOrder (a, b) {
    const order = this.properties?.getValue?.("pageNumberOrder");
    const indexA = _.findIndex(
        order, 
        o => o === this.elementGroupsById?.[a?.structureData?.parentGroupId]?.pageNumber
    );
    const indexB = _.findIndex(
        order, 
        o => o === this.elementGroupsById?.[b?.structureData?.parentGroupId]?.pageNumber
    );
    return (indexA || 0) - (indexB || 0);
}

export function sortByPageNumberOrder (a, b) {
    const order = this.properties?.getValue?.("pageNumberOrder");
    const indexA = _.findIndex(order, o => o === a?.pageNumber);
    const indexB = _.findIndex(order, o => o === b?.pageNumber);
    return (indexA || 0) - (indexB || 0);
}

export function humanFileSize (bytes, binary = false, dp = 1) {
    let bytesInternal = +bytes;

    if (!_.isFinite(bytesInternal)) {
        return this.$t(`base.fileUpload.unknownFileSize`);
    }
  
    const stepSize = binary ? 1024 : 1000;

    if (Math.abs(bytesInternal) < stepSize) {
        return `${bytesInternal} B`;
    }

    const units = binary 
        ? ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
        : ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let unitStep = -1;
    const precision = 10 ** dp;

    while (
        unitStep < units.length - 1 &&
        Math.round(Math.abs(bytesInternal) * precision) / precision >= stepSize 
    ) {
        ++unitStep;
        bytesInternal = bytesInternal / stepSize;
    }

    return `${bytesInternal.toFixed(dp)} ${units[unitStep]}`;
}


/** *
 * Utilities for converting between backend and frontend properties lists/objects
 * NOTE: These should only be in use in the post editor anymore. 
 * TODO: GET RID OF THESE!!! Convert post editor to using model instances and 
 * class based Properties
 ***/
export function deserializeProperties (object) {
    // Used for modfiying the .properties of an API response object (post, asset,
    // questionnaire, etc.) from an array of {key: , value: , languageCode: } into an 
    // object: { 
    //     <lang_code_1>: { 
    //         <prop_1_key>: <prop_1_value>, 
    //         <prop_2_key>: <prop_2_value>, 
    //         ...
    //     },
    //     <lang_code_2>: {...},
    // }
    if (!object || !object.properties) {
        log("ERROR: deserializer called with missing / incomplete argument: ", object);
        return {};
    } 
    if (!Array.isArray(object.properties)) {
        log("WARNING: desearilizer called on already desearilised object: ", object);
        return object.properties;
    }
    const propertyObject = {};
    for (const property of object.properties) {
        if (!property.languageCode) {
            property.languageCode = "*";
        }
        if (!propertyObject[property.languageCode]) {
            propertyObject[property.languageCode] = {};
        }
        propertyObject[property.languageCode][property.key] = property.value;
    }
    object.properties = propertyObject;
    return propertyObject;
}

export function serializeProperties (object) {
    // Used for turing a property object: { 
    //     <lang_code_1>: { 
    //         <prop_1_key>: <prop_1_value>, 
    //         <prop_2_key>: <prop_2_value>, 
    //         ...
    //     },
    //     <lang_code_2>: {...},
    // }
    // into an array of {key: , value: , languageCode: } suitable for API consumption
    const propertyList = [];
    _.forOwn(object, (properties, languageCode) => {
        _.forOwn(properties, (value, key) => {
            propertyList.push({value, key, languageCode});
        });
    });
    return propertyList;
}
export function getPropertyModel (object, type, languageCode) {
    // Returns an array of {key: , value: , languageCode: } properties with default values
    // inserted if 'object' does not have a value specified.
    // Also note that the array includes _all_ properties that are in the spec for the 
    // 'object'. 'type' indicates the spec _class_ and object[typeId] indicates the 
    // object's type within the _class_
    let typeClass, typeId;
   
    if (type === "postElement") {
        typeClass = PostElementType;
        typeId = "elementType";
    } 
   
    else if (type === "post") {
        typeClass = { map: { "post": { 
            propertiesList: PostPropertyList,
            properties: PostPropertyMap, 
        } } };
        typeId = "post";
    } 

    if (
        !typeClass?.map?.[object?.[typeId]] &&
        !typeClass?.map?.[typeId]
    ) {
        return [];
    }
    const properties = ( 
        typeClass.map?.[object?.[typeId]]?.propertiesList ||
        typeClass.map?.[typeId]?.propertiesList ||
        []
    );

    const lang = languageCode || "*";
    // Fill values from existing properties or typeClass defaults
    properties.map((property) => {
        if (object.properties && object.properties[lang] && !_.isNil(object.properties[lang][property.key])) {
            if (property.inputType === "dateTimeLocal") {
                // TODO: this code makes puppies cry. make it better.
                if(property.type.startsWith("list")) {
                    property.value = object.properties[lang][property.key].map(
                        value => new Date(value)
                    );
                }
                else {
                    const date = new Date(Date.parse(object.properties[lang][property.key]));
                    property.value = date;
                }
            } else {
                property.value = _.cloneDeep(object.properties[lang][property.key]);
            }
        } else {
            property.value = _.clone(
                typeClass.map?.[object?.[typeId]]?.properties?.[property?.key]?.default ||
                typeClass.map?.[typeId]?.properties?.[property?.key]?.default
            );
        }
    });
    return properties;
}


/** *
 * Utilities for getting names of asset types / asset groups and asset-group memberships
 ***/
export const assetGroups = [];
export const assetTypeNames = [];
export const assetGroupMembers = {};
export const assetGroupForType = {};
for (const assetType of AssetType.list) {
    if (assetType.hiddenFromInterface) continue;
    if (assetTypeNames.indexOf(assetType.name) === -1) {
        assetTypeNames.push(assetType.name);
    }
    if (assetGroups.indexOf(assetType.group) === -1) {
        assetGroups.push(assetType.group);
    }
    if (!assetGroupMembers[assetType.group]) {
        assetGroupMembers[assetType.group] = [assetType];
    } else {
        assetGroupMembers[assetType.group].push(assetType);
    }
    assetGroupForType[assetType.name] = assetType.group;
}

export default {};
