import { createI18n } from "vue-i18n";

import _ from "lodash";

import api from "@/api";
import log from "@/log";
import store from "@/store";
import config from "@/config";

const i18n = createI18n({
    legacy: true,
    locale: "en",
    fallbackLocale: "en",
    messages: {},
    missingWarn: false,
    warnHtmlInMessage: "off",
    // we're getting these two below a lot when changing lang
    // so disabled the warnings for not flooding console log
    silentFallbackWarn: true,
    silentTranslationWarn: true,
});

const loadedLanguages = [];

function setI18nLanguage (lang) {
    i18n.global.locale = lang;
    store.commit("setLanguage", lang);
    api.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
}

export function setLanguageAsync(lang) {
    if (!_.find(definedLanguages, {"code": lang})) {
        return Promise.reject(new Error(`Unsupported language: ${lang}`));
    }
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }
    const proj = config.i18nProject || "default";
    return import(
        /* webpackChunkName: "lang-[request]" */ 
        `@/i18n/messages/${proj}/client-${lang}`
    ).then(response => {
        i18n.global.setLocaleMessage(lang, response.default);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
    });
}

export function loadLanguageAsync(lang) {
    if (!lang) return Promise.reject(false);
    if (!_.find(definedLanguages, {"code": lang})) {
        return Promise.reject(new Error(`Unsupported language: ${lang}`));
    }
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(true);
    }
    const proj = config.i18nProject || "default";
    return import(
        /* webpackChunkName: "lang-[request]" */ 
        `@/i18n/messages/${proj}/client-${lang}`
    ).then(response => {
        i18n.global.setLocaleMessage(lang, response.default);
        loadedLanguages.push(lang);
        return true;
    });
}

export function isLanguageLoaded (lang) {
    return !!loadedLanguages?.includes?.(lang);
}

// Some convenience exports
export const definedLanguages = config?.i18nDefinedLanguages;
const definedCodes = definedLanguages.map(l => l.code);

const editorCodes = _.difference((
        config?.i18nEditorLanguageIncludeCodes?.length
        ? config.i18nEditorLanguageIncludeCodes
        : ["en"]
    ),
    config.i18nEditorLanguageExcludeCodes || []
);
export const editorLanguages = _.filter(definedLanguages, 
    l => _.includes(editorCodes, l.code)
);

const userProfileCodes = _.difference((
        config?.i18nUserProfileLanguageIncludeCodes?.length
        ? config.i18nUserProfileLanguageIncludeCodes
        : definedCodes
    ),
    config.i18nUserProfileLanguageExcludeCodes || []
);
export const userProfileLanguages = _.filter(definedLanguages, 
    l => _.includes(userProfileCodes, l.code)
);

const contentCodes = _.difference((
        config?.i18nContentLanguageIncludeCodes?.length
        ? config.i18nContentLanguageIncludeCodes
        : definedCodes
    ),
    config.i18nContentLanguageExcludeCodes || []
);
export const contentLanguages = _.filter(definedLanguages, 
    l => _.includes(contentCodes, l.code)
);

const serviceCodes = _.difference((
        config?.i18nServiceLanguageIncludeCodes?.length
        ? config.i18nServiceLanguageIncludeCodes
        : definedCodes
    ),
    config.i18nServiceLanguageExcludeCodes || []
);
export const serviceLanguages = _.filter(definedLanguages, 
    l => _.includes(serviceCodes, l.code)
);

// Get different locale settings
const storeLocale = store.state.locale;
const storeEditorLocale = store.state.editorLocale;
const browserLocale = (navigator?.userLanguage || navigator?.language)?.slice?.(0, 2);
const defaultLocale = config.i18nDefaultLocale || "en";

// If we do not have any language loaded yet, we make an interval
// to periodically try and load the language
let intervalId;
let isLoading = false;
if (!loadedLanguages.length || !store.state.locale) {
    let attempts = 0;
    intervalId = setInterval(() => {
        if (loadedLanguages.length && store.state.locale) {
            initEditorLocale();
            clearInterval(intervalId);
            attempts = 0;
            return;
        }
        if (isLoading) return;
        if (storeLocale && _.find(definedLanguages, {"code": storeLocale})) {
            isLoading = true;
            setLanguageAsync(storeLocale)
                .catch(e => { 
                    attempts++;
                    if ((attempts % 10) === 0) {
                        log(e, {
                            sentry: true,
                            message: `i18n/index.js: Could not load store language ${storeLocale} after ${attempts} attempts.`,
                            class: "shit-fan",
                        });
                    }
                })
                .finally(r => { isLoading = false; });
        }
        else if (browserLocale && _.find(definedLanguages, {"code": browserLocale})) {
            isLoading = true;
            setLanguageAsync(browserLocale)
                .catch(e => { 
                    attempts++;
                    if ((attempts % 10) === 0) {
                        log(e, {
                            sentry: true,
                            message: `i18n/index.js: Could not load browser language ${browserLocale} after ${attempts} attempts.`,
                            class: "shit-fan",
                        });
                    }
                })
                .finally(r => { isLoading = false; });
        }
        else {
            isLoading = true;
            setLanguageAsync(defaultLocale)
                .catch(e => { 
                    attempts++;
                    if ((attempts % 10) === 0) {
                        log(e, {
                            sentry: true,
                            message: `i18n/index.js: Could not load default language ${defaultLocale} after ${attempts} attempts.`,
                            class: "shit-fan",
                        });
                    }
                })
                .finally(r => { isLoading = false; });
        }
    }, 100);
}

function initEditorLocale () {
    if (storeEditorLocale) return;
    if (_.find(editorLanguages, {"code": storeLocale})) {
        store.commit("setEditorLanguage", storeLocale);
    }
    else if (_.find(editorLanguages, {"code": browserLocale})) {
        store.commit("setEditorLanguage", browserLocale);
    }
    else if (_.find(editorLanguages, {"code": defaultLocale})) {
        store.commit("setEditorLanguage", defaultLocale);
    }
    else {
        store.commit("setEditorLanguage", "en");
    }
}



i18n.editorLanguages = editorLanguages;
i18n.definedLanguages = definedLanguages;
i18n.isLanguageLoaded = isLanguageLoaded;
i18n.setLanguageAsync = setLanguageAsync;
i18n.loadLanguageAsync = loadLanguageAsync;
export default i18n;