import _ from "lodash";

import i18n from "@/i18n";

import { DateTime } from "luxon";

export default {
    setAppInitialized: (state) => { state.initialized = true; },
    setPendingInvitations: (state, value) => { state.pendingInvitations = value; },
    browserWarningWasShown: (state) => { state.browserWarningWasShown = true; },
    doNotShowBrowserWarningAgain: (state) => {
        state.browserWarningWasShown = true;
        localStorage.setItem("do-not-show-browser-warning", true);
    },
    surveyLinkWasShown: (state) => { state.surveyLinkWasShown = true; },
    bannerLinkWasShown: (state) => { state.bannerLinkWasShown = true; },
    doNotShowSurveyLinkAgain: (state, id) => {
        state.surveyLinkWasShown = true;
        localStorage.setItem(`do-not-show-survey-id-${id}`, true);
    },
    doNotShowBannerLinkAgain: (state, id) => {
        state.bannerLinkWasShown = true;
        localStorage.setItem(`do-not-show-banner-${id}`, true);
    },
    setSuperuserLicenseOverride: (state, value) => { 
        state.superuserLicenseOverride = value; 
    },
    updateUser: (state, user) => {
        if (state?.user?.userId !== user?.userId) {
            // We have a previous users' local state an need to clean up the local
            // state before proceeding.
            state.access = {};
            state.activeGroupId = null;
            state.activeGroupName = null;
            state.recentGroups = [];
        }
        state.user = user;
    },
    clearUser: (state) => {
        state.user = {};
    },
    setBulkEditPosts: (state, posts) => {
        state.bulkEditPosts = posts;
    },
    setNewAssetType: (state, assetType) => {
        state.newAssetType = assetType;
    },
    addVote: (state, vote) => {
        const projectId = vote?.project?.projectId;
        if (!projectId) return;
        state.votes[projectId] = vote;
    },
    setActiveGroup: (state, group) => {
        state.activeGroupId = group.groupId;
        state.activeGroupName = group.name || group.groupName; // <-- /list and /get endpoints return different properties
        const index = _.findIndex(state.recentGroups, {groupId: group.groupId});
        const groupToInsert = {
            groupId: group.groupId,
            groupName: group.name || group.groupName, // <-- /list and /get endpoints return different properties
        };
        if (index === -1) {
            state.recentGroups.unshift(groupToInsert);
            state.recentGroups = _.slice(state.recentGroups, 0, 5);
        } else {
            _.remove(state.recentGroups, {groupId: group.groupId});
            state.recentGroups.unshift(groupToInsert);
        }
    },
    setAccess: (state, access) => {
        const groupId = access?.groupId;
        const types = access?.types || access?.accessTypeList || [];
        const categories = access?.categories || access?.accessCategoryList || [];
        const fetchedTime = DateTime.local();
        state.access = {
            ...state.access,
            [groupId]: {
                groupId: groupId, 
                fetchedTime: fetchedTime,
                types: types,
                categories: categories,
            },
        };
    },
    setLicense: (state, license) => {
        if (!license || !Array.isArray(license.licenseProperties)) return;
        const groupId = license.groupId || license.rootGroupId;
        const fetchedTime = DateTime.local();
        state.licenses = {
            ...state.licenses, 
            [groupId]: {
                groupId: groupId, 
                fetchedTime: fetchedTime,
                properties: {},
            },
        };
        license.licenseProperties.forEach(l => {
            if (l.expired) return;
            state.licenses[groupId].properties = {
                ...state.licenses[groupId].properties,
                [l.key]: l.value,
            };
        });
    },
    removeGroupFromRecents: (state, groupId) => {
        _.remove(state.recentGroups, { groupId });
    },
    deactivateActiveGroup: (state) => {
        state.access[state.activeGroupId] = null;
        state.activeGroupId = null;
        state.activeGroupName = null;   
    },
    setUserHasGroups: (state, bool) => {
        state.hasGroups = bool;
        if (!bool) {
            state.access = {};
            state.activeGroupId = null;
            state.activeGroupName = null;
            state.recentGroups = [];
        }
    },
    logout: (state) => {
        state.user = {};
    },
    setLanguage: (state, value) => {
        const language = _.find(i18n.definedLanguages, {"code": value});
        if (language) {
            state.locale = value;
            state.rtl = !!language?.rtl;
        }
    },
    setEditorLanguage: (state, value) => {
        const language = _.find(i18n.editorLanguages, {"code": value});
        if (language) {
            state.editorLocale = value;
            state.editorRtl = !!language?.rtl;
        }
    },
    clearMapView: (state) => {  
        state.currentMapView = {};
    },
    setMapView: (state, view) => {
        if (
            !view || 
            !_.isFinite(+view.lat) ||
            !_.isFinite(+view.lng) ||
            !_.isFinite(+view.zoom)
        ) return;
        state.currentMapView = view;
    },
    setInitialMapView: (state, view) => {
        if (
            !view.pageId ||
            !view || 
            !_.isFinite(+view.lat) ||
            !_.isFinite(+view.lng) ||
            !_.isFinite(+view.zoom)
        ) return;
        state.initialMapView = view;
    },
    setResponseDataNeedsRefresh: (state, value) => {
        state.responseDataNeedsRefresh = value;
    },
};