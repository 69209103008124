/* eslint-disable */ 
(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
  typeof define === 'function' && define.amd ? define(['exports'], factory) :
  (factory((global['custom-svg-icons'] = {})));
}(this, (function (exports) { 'use strict';

  var prefix = "fab";
  var faMapbox = {
    prefix: 'fab',
    iconName: 'mapbox',
    icon: [256, 256, [], "ff00", "M128,0 C57.3048369,0 0,57.3048369 0,128 C0,198.695163 57.3048369,256 128,256 C198.695163,256 256,198.695163 256,128 C256,57.3048369 198.695163,0 128,0 Z M188.760405,159.388076 C144.989876,203.158605 66.807649,189.192351 66.807649,189.192351 C66.807649,189.192351 52.6974128,111.154106 96.6119235,67.2395951 C120.944882,42.9066367 161.259843,43.9145107 186.744657,69.2553431 C212.229471,94.5961755 213.093363,135.055118 188.760405,159.388076 L188.760405,159.388076 Z M142.686164,75.0146232 L130.15973,100.787402 L104.386952,113.313836 L130.15973,125.84027 L142.686164,151.613048 L155.212598,125.84027 L180.985377,113.313836 L155.212598,100.787402 L142.686164,75.0146232 Z"]
  };

  var faArea = {
    prefix: 'fal',
    iconName: 'area',
    icon: [576, 512, [], "ff01", "M514.92 76.65C467.92 23.11 416.28 0 368.85 0 298.27 0 237 51.17 214.1 129.39 165 269.32 1.37 212.32 0 351.63-1.13 467.49 126.32 512 239.31 512q8.41 0 16.69-.32c87.78-3.41 187.32-37.1 270.49-131.85C596.78 299.75 591.6 164 514.92 76.65zm-12.47 282.07C418.8 454 321.59 477.11 254.75 479.7q-7.65.3-15.44.3c-77 0-143.78-21.08-178.76-56.4C41.06 403.92 31.73 380.48 32 352c.48-49.57 23.49-61.67 75.5-84.31 48.37-21.11 108.57-47.32 136.79-127.69l.28-.8.24-.82C263.72 73.75 312.41 32 368.85 32c42.06 0 84.26 22.74 122 65.76 66.36 75.58 71.55 192.65 11.6 260.96z"]
    // icon: [576, 512, [], "ff01", "M466.81 118.83C435.3 82.94 401.42 64 368.85 64c-41.87 0-78.51 32.73-93.33 83.38l-.63 2.19c-17.44 60.74-69.45 110.34-154.6 147.41-57.29 24.9-56.54 60.8-53.57 75.07 10.7 51.39 91.56 74.8 162.16 74.8 97.65 0 188.59-39.8 249.5-109.2 48.92-55.73 43.62-155.94-11.57-218.82zm-12.48 197.71c-64.24 73.19-152.69 98.31-225.45 98.31-105.46 0-177.94-52.81-95.81-88.57 146.41-63.75 166.92-148.6 173.16-170C317 119.66 341.54 96 368.85 96c22.76 0 49 15.62 73.91 44 44.37 50.49 49.78 133 11.57 176.54z"]
  };

  var faBing = {
    prefix: 'fab',
    iconName: 'bing',
    icon: [24, 24, [], "ff02", "M3.605 0L8.4 1.686V18.56l6.753-3.895-3.31-1.555-2.09-5.2 10.64 3.738v5.435L8.403 24l-4.797-2.67V0z"]
  };

  var _iconsCache = {
    faArea: faArea,
    faMapbox: faMapbox,
  };

  exports.fab = _iconsCache;
  exports.prefix = prefix;
  exports.faArea = faArea;
  exports.faBing = faBing;
  exports.faMapbox = faMapbox;

  Object.defineProperty(exports, '__esModule', { value: true });

})));
