import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/group/connections/list": {
        required: ["groupId"],
        listResponse: "connections",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    list: (options, args) => apiRequest(options, "/user/group/connections/list", args),
};
