import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/group/parents/list": {
        required: ["groupId"],
        listResponse: "groups",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    list: (options, args) => apiRequest(options, "/user/group/parents/list", args),
};
