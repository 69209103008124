import { apiRequestFactory } from "@/api/util";
import { MeasurementUnitPropertiesMap } from "@/../../server/src/shared/webdist/measurementUnit";
import { ParticipatoryProjectPropertiesMap } from "@/../../server/src/shared/webdist/participatoryProject";

const apiSpec = {
    "/participation/project/get": {
        required: ["projectId"],
    },
    "/participation/project/view": {
        required: ["projectId"],
    },
    "/participation/project/list": {
        required: ["ownerGroupId"],
        listResponse: "projects",
    },
    "/participation/project/create": {
        required: ["ownerGroupId"],
    },
    "/participation/project/update": {
        required: ["projectId"],
    },
    "/participation/project/disable": {
        required: ["projectId"],
        booleanResponse: "disabled",
    },
    "/participation/project/restore": {
        required: ["projectId"],
        booleanResponse: "restored",
    },
};

const typeSpec = {
    project: {
        properties: ParticipatoryProjectPropertiesMap, 
    },
};
const currencyTypeSpec = {
    currency: {
        properties: MeasurementUnitPropertiesMap,
    },
};

const apiRequest = apiRequestFactory(apiSpec, typeSpec, null, "project", [{
    location: "currencies", 
    TypeMap: currencyTypeSpec,
    singularTypeName: "currency",
}]);

export default {
    get: (options, args) => apiRequest(options, "/participation/project/get", args),
    view: (options, args) => apiRequest(options, "/participation/project/view", args),
    list: (options, args) => apiRequest(options, "/participation/project/list", args),
    create: (options, args) => apiRequest(options, "/participation/project/create", args),
    update: (options, args) => apiRequest(options, "/participation/project/update", args),
    disable: (options, args) => apiRequest(options, "/participation/project/disable", args),
    restore: (options, args) => apiRequest(options, "/participation/project/restore", args),
};
