import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/participation/project/vote/aggregate/view": {
        required: ["projectId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    view: (options) => apiRequest(options, "/participation/project/vote/aggregate/view"),
};