import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/participation/idea/comment/moderation/disable": {
        required: ["commentId", "ideaId", "projectId"],
    },
    "/participation/idea/comment/moderation/restore": {
        required: ["commentId", "ideaId", "projectId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    disable: (options) => apiRequest(options, "/participation/idea/comment/moderation/disable"),
    restore: (options) => apiRequest(options, "/participation/idea/comment/moderation/restore"),
};