import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/participation/proposal/cost/set": {
        required: ["amount", "currencyId", "projectId", "proposalId"],
    },
    "/participation/proposal/cost/remove": {
        required: ["currencyId", "projectId", "proposalId"],
        booleanResponse: "removed",
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    set: (options) => apiRequest(options, "/participation/proposal/cost/set"),
    remove: (options) => apiRequest(options, "/participation/proposal/cost/remove"),
};