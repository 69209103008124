import { QuestionnaireElementType } from "@/../../server/src/shared/webdist/questionnaireElementType";
import { AssetType } from "@/../../server/src/shared/webdist/assetType";
import { QuestionnairePropertyMap } from "@/../../server/src/shared/webdist/questionnaireProperties";
import { Properties } from "@/store/util";

import _ from "lodash";
import bus from "@/bus";

export default {
    currentPageId: (state, value) => { state.currentPageId = value; },
    saveRespondent: (state, payload) => {
        const qId = payload.questionnaireId || state.questionnaireId;
        if (!qId || !payload.respondent) return;
        state.respondentIds[qId] = payload.respondent.respondentId;
        state.respondents[qId] = payload.respondent;
    },
    updatePublicationConsent: (state, payload) => {
        const qId = (payload && payload.questionnaireId) || state.questionnaireId;
        if (!qId) return;
        state.respondents[qId].publicationConsent = payload && payload.publicationConsent;
    },
    updateParticipatoryConsent: (state, payload) => {
        const qId = (payload && payload.questionnaireId) || state.questionnaireId;
        if (!qId) return;
        state.respondents[qId].participatoryConsent = payload && payload.participatoryConsent;
    },
    responsesLoaded: (state, payload) => {
        state.isResponsesLoaded = true;
    },
};
