import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const getDefaultState = () => {
    return {
        selectedPageId: null,
        selectedPopupId: null,
        selectedElementId: null,
        showElementLibrary: false,
        showElementPopupBar: false,
        showElementSettings: false,
        showQuestionnaireSettings: false,

        // TODO: move to using questionnaire.assets / publication.assets
        assetCache: {},
    };
};

const state = getDefaultState();

const editorModule = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default editorModule;
export { getDefaultState };