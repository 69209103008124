import { apiRequestFactory } from "@/api/util";

const apiSpec = {
    "/user/group/super/update": {
        required: ["groupId"],
    },
};

const apiRequest = apiRequestFactory(apiSpec);

export default {
    update: (options, args) => apiRequest(options, "/user/group/super/update", args),
};
